import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroPartRequestDocument, GetMroPartRequestsDocument, UpdateMroPartRequestDocument } from 'graphql/generated';
import { useState } from 'react';
import { useSession } from 'contexts';
import StatusButton from 'components/StatusButton/StatusButton';
import Modal from 'components/Modal/Modal';
import { PencilIcon } from '@heroicons/react/24/solid';
import RequestEdit from '../partials/RequestEdit';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import FulfillRequest from '../partials/FulfillRequest';
import { formatApiDate } from 'utils/formatter';
import { webFormat } from 'utils/statuses';
import NewPart from '../../New';
import { Link } from 'react-router-dom';

const RequestFlyout = function ({ partRequestId }: { partRequestId: string }) {
  const { user } = useSession();
  const { data: { mroWorkOrderPart } = {} } = useQuery(GetMroPartRequestDocument, { variables: { partRequestId } });
  const [updatePartRequest] = useMutation(UpdateMroPartRequestDocument, {
    refetchQueries: [GetMroPartRequestsDocument],
  });
  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  return (
    <>
      <Flyout {...flyoutProps} />
      {modal && (
        <Modal
          cancel={() => setModal(false)}
          accept={() => updatePartRequest({ variables: { input: { id: partRequestId, status: 'CANCELLED' } } })}
          message={'Are you sure you want to cancel this request?'}
        />
      )}
      <div className="p-4">
        {!edit ? (
          <>
            {/* AIRCRAFT INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white gap-5">
              <div className="flex justify-between items-center mt-4 pb-2 border-b border-slate-100">
                <div className='flex flex-col gap-2'>
                  <Link to={ mroWorkOrderPart?.mroPart ? mroWorkOrderPart?.mroPart.id : '#' } className={`font-bold ${ mroWorkOrderPart?.mroPart && 'underline' }`}>
                    {mroWorkOrderPart?.partNumber}
                  </Link>
                  {(mroWorkOrderPart?.mroPart?.description || mroWorkOrderPart?.partDescription ) && (
                    <span className="text-sm mt-[-10px]">Description: {mroWorkOrderPart?.mroPart?.description ?? mroWorkOrderPart?.partDescription}</span>
                  )}
                </div>
               
                <PencilIcon onClick={() => setEdit(true)} className="h-6 w-7 p-1 mb-2 bg-slate-100 rounded hover:opacity-70 cursor-pointer" />
              </div>
              <div className="flex justify-between">
                <span>Status: </span>
                <span className="w-[150px]">
                  <StatusButton readOnly={true} context="parts" text={mroWorkOrderPart?.status} className="" />
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font text-brand-dark">Need By:</span>
                <span>{formatApiDate(mroWorkOrderPart?.neededDate)}</span>
              </div>
              { mroWorkOrderPart?.shippingType && <div className="flex justify-between">
                <span className="font text-brand-dark">Shipping Type:</span>
                <span className="font-semibold">{webFormat(mroWorkOrderPart?.shippingType)}</span>
              </div> }
              <div className="flex justify-between mt-5">
                <span>Quantity: </span>
                <span className="font-bold">{mroWorkOrderPart?.quantity}</span>
              </div>
              <div className="flex justify-between mt-5">
                <span>Unit Price: </span>
                <span className="font-bold">${Number(mroWorkOrderPart?.unitPrice).toFixed(2)}</span>
              </div>
              { mroWorkOrderPart?.costPrice && mroWorkOrderPart?.costPrice !== 0 && (<div className="flex justify-between mt-5">
                <span>Cost Price: </span>
                <span className="font-bold">${Number(mroWorkOrderPart?.costPrice).toFixed(2)}</span>
              </div> )}
              <div className={`${mroWorkOrderPart?.notes ? '' : 'hidden'} mt-2`}>
                <dt className="text-sm font-medium text-slate-500">Notes</dt>
                <p className="rounded border border-slate-200 p-4 text-sm bg-slate-50">{mroWorkOrderPart?.notes}</p>
              </div>
            </div>
            {/* BUTTONS */}
            <div className="flex justify-end items-center mt-4 gap-3">
              {(mroWorkOrderPart?.status === 'REQUESTED' ||
                mroWorkOrderPart?.status === 'PENDING' ||
                mroWorkOrderPart?.status === 'IN_PROGRESS' ||
                mroWorkOrderPart?.status === 'AWAITING_BACKORDER') && (
                <Button text="Cancel Request" color="" className="bg-red-500" onClick={() => setModal(true)} size="xs" />
              )}
              { mroWorkOrderPart?.mroPart ? ( 
                <>
                  {(mroWorkOrderPart?.status === 'AWAITING_BACKORDER' || mroWorkOrderPart?.status === 'IN_PROGRESS') && (
                    <Button
                      text="Cancel Awaiting Order"
                      color="pale"
                      size="xs"
                      onClick={() => {
                        updatePartRequest({ variables: { input: { id: partRequestId, status: 'PENDING' } } });
                      }}
                    />
                  )}
                  {(mroWorkOrderPart?.status === 'REQUESTED' || mroWorkOrderPart?.status === 'PENDING') && (
                    <Button
                      text="Mark as Awaiting Order"
                      color="pale"
                      size="xs"
                      onClick={() => {
                        updatePartRequest({ variables: { input: { id: partRequestId, status: 'AWAITING_BACKORDER' } } });
                      }}
                    />
                  )}
                  {mroWorkOrderPart?.status === 'REQUESTED' && (
                    <Button
                      text="Mark as Pending"
                      color=""
                      size="xs"
                      onClick={() => {
                        updatePartRequest({ variables: { input: { id: partRequestId, status: 'PENDING' } } });
                      }}
                    />
                  )}
                  {(mroWorkOrderPart?.status === 'REQUESTED' ||
                    mroWorkOrderPart?.status === 'PENDING' ||
                    mroWorkOrderPart?.status === 'BACKORDER_CREATED') && (
                    <Button
                      text="Fulfill Request"
                      color="navy"
                      size="xs"
                      onClick={() => {
                        updateFlyout({
                          title: 'Fulfill Request',
                          content: <FulfillRequest closeFlyout={closeFlyout} partRequestId={partRequestId} />,
                        });
                      }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Button
                    text="Add and Link Part"
                    color="navy"
                    size="xs"
                    onClick={() => {
                      updateFlyout({
                        title: 'Add and Link Part',
                        content: <NewPart closeFlyout={() => closeFlyout()} 
                                  partNumber={mroWorkOrderPart.partNumber}
                                  partDescription={mroWorkOrderPart?.partDescription}
                                  workOrderPartId={mroWorkOrderPart.id} />,
                      });
                    }}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <RequestEdit
            partRequestId={partRequestId}
            setEdit={setEdit}
            lockRequest={
              mroWorkOrderPart?.status === 'READY_FOR_PICKUP' ||
              mroWorkOrderPart?.status === 'PICKED_UP' ||
              mroWorkOrderPart?.status === 'INSTALLED'
            }
          />
        )}
      </div>
    </>
  );
};

export default RequestFlyout;
