import { useMutation, useQuery } from "@apollo/client";
import Card from "components/Card/Card";
import { FullFormikInput } from "components/Form/StandardForm";
import { useSession } from "contexts";
import { Field, Formik, FormikHelpers, Form } from "formik";
import { DeleteLogBookDocument, GetComponentsDocument, GetCraftDocument, GetIntervalsForCraftDocument, GetLogbookDocument, GetLogbooksDocument, LogBookUpdateInput, UpdateLogBookDocument } from "graphql/generated";
import { useEffect, useState } from "react";
import { StyledSelect } from "../../Squawks/Edit";
import Multiselect from "components/Multiselect/Multiselect";
import Button from "components/Button/Button";
import Modal from "components/Modal/Modal";

const Edit = function ({ id, closeFlyout }: { id: string, closeFlyout: () => void }) {
  const { craftId } = useSession();
  const { data: { logBook } = { } } = useQuery(GetLogbookDocument, { variables: { logBookId: id }, pollInterval: 0 });
  const { data: { craft } = { craft: undefined } } = useQuery(GetCraftDocument, { variables: { craftId }, pollInterval: 0 });
  const { data: { craft : { intervalsNonConsumable: intervals } = {} } = {} } = useQuery(GetIntervalsForCraftDocument, { variables: { craftId } });
  const [ updateLogBook ] = useMutation(UpdateLogBookDocument, { refetchQueries: [{ query: GetLogbookDocument, variables: { logBookId: id } }] });
  const [ removeLogBook ] = useMutation( DeleteLogBookDocument, { refetchQueries: [{ query: GetLogbooksDocument, variables: { craftId } }] });
  const [ showModal, setShowModal ] = useState(false);
  const [prefill, setPrefill] = useState({
    title: logBook?.name ?? '',
    description: logBook?.description ?? '',
    component: logBook?.components?.[0]?.id ?? '',
    intervals: logBook?.intervals?.map((interval) => ( 
      { name: interval.name, value: interval }
    )),
  });

  useEffect(() => {
    if (craft) {
      setPrefill((prev) => ({ ...prev, component: craft?.components[0].id }));
    }
  }, [craft]);

  useEffect(() => {
    if (logBook) {
      setPrefill({
        title: logBook?.name ?? '',
        description: logBook?.description ?? '',
        component: logBook?.components?.[0]?.id ?? craft?.components[0].id,
        intervals: logBook?.intervals?.map((interval) => ( 
          { name: interval.name, value: interval }
        )),
      });
    }
  }, [logBook]);

  const handleSubmit = async (values: typeof prefill, { setSubmitting }: FormikHelpers<typeof prefill>) => {  
    const logBookInput: LogBookUpdateInput = {
      id,
      name: values.title,
      description: values.description,
      craft: { connect: { id: craftId } },
      components: { disconnect:[], connect: [{ id: values.component }] },
      ...( values.intervals.length > 0 && {intervals: { 
        disconnect: logBook?.intervals.filter((interval) => 
          !values?.intervals?.find((inter) => inter.value.id === interval.id ) ).map((interval) => ({
            id: interval.id
        })), 
        connect: values?.intervals?.map((interval) => ({
          id: interval.value.id
      })) } } )
    };
    console.log(logBookInput);
    await updateLogBook({ variables: { input: logBookInput } });
    setSubmitting(false);
    setPrefill({ ...prefill });
    closeFlyout();
  };

  return (
    <>
      { showModal && <Modal message={'You are about to delete this logbook.'} accept={ () => {
        removeLogBook({ variables: { id } });
        closeFlyout();
        setShowModal(false);
      } } cancel={setShowModal} /> }
      <Formik enableReinitialize initialValues={prefill} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <Card className='m-4'>
              <dl>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='title' label='Title' isVertical={true} className='w-full' />
                </div>
                <div className="p-4 flex items-center justify-center w-full">
                  <FullFormikInput name='description' label='Description' isVertical={true} className='w-full text-sm' />
                </div>
                <div className="p-4 flex items-center justify-between">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Component</dt>
                  <Field as={StyledSelect} name="component">
                    {craft?.components.map((component) => (
                      <option key={component.id} value={component.id}>
                        {component.name}
                      </option>
                    ))}
                  </Field>
                </div>
                <div className="p-4 flex items-center justify-between w-full">
                  <dt className="text-sm font-medium text-slate-500 w-1/3">Intervals</dt>
                  <div className='w-full ml-14'>
                    <Multiselect initialSelected={values.intervals} items={intervals?.map((interval) => ( 
                      { name: interval.name, value: interval }
                    ))} setSelectedItems={(items) => setFieldValue('intervals', items)} />
                  </div>
                </div>
              </dl>
            </Card>
            <div className="px-4 mb-10 flex items-center flex-wrap justify-end gap-4">
              <Button
                type="button"
                onClick={closeFlyout}
                size='sm'
                text='Cancel' />
              <Button
                type="button"
                onClick={() => setShowModal(true)}
                color='red'
                size='sm'
                text='Delete' />
              <Button
                type="submit"
                size='sm'
                color="navy"
                disabled={isSubmitting}
                text='Save' />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Edit;
