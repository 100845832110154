import { useMutation, useQuery } from '@apollo/client';
import { ArrowPathIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import Button from 'components/Button/Button';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { useSession } from 'contexts';
import { Field, Form, Formik } from 'formik';
import { GetMroEmployeeDocument, UpdateMroEmployeeDocument } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { colors } from 'utils/statuses';
import * as Yup from 'yup'; 

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string(),
  pin: Yup.number().lessThan(10000, 'Pin must be 4 digits').moreThan(999, 'Pin must be 4 digits'),
});

export default function ProfileSettings() {
  const { user } = useSession();
  const [openColor, setOpenColor] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [pfColor, setPfColor] = useState('blue');
  const { toastProps, showToast } = useToast();
  const [pfp, setPfp] = useState<File>(null);
  const [updateProfile] = useMutation(UpdateMroEmployeeDocument, {
    refetchQueries: [{ query: GetMroEmployeeDocument, variables: { mroEmployeeId: user?.mroEmployeeProfileId } }],
  });
  const { data: { mroEmployeeProfile: profile } = {}, error } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: user?.mroEmployeeProfileId },
  });
  useEffect(() => {
    setPfColor(profile?.profileColor);
  }, [profile]);

  const handlePfp = async (e) => {
    e.preventDefault();
    if (pfp) {
      const formData = new FormData();
      formData.append('files', pfp);
      const res = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: formData });
      const body = await res.json();
      updateProfile({
        variables: {
          input: {
            id: user.mroEmployeeProfileId,
            profilePicture: { connect: { id: body[0].id } },
          },
        },
      });
    } else if (pfColor) {
      updateProfile({
        variables: {
          input: {
            id: user.mroEmployeeProfileId,
            profileColor: pfColor,
            profilePicture: null,
          },
        },
      });
    }
    setOpenColor(false);
  };

  const [formPrefill, setFormPrefill] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    airmenName: '',
    airmenNumber: '',
    licenseType: '',
    pin: '',
  });

  useEffect(() => {
    if (profile)
      setFormPrefill((prefill) => ({
        ...prefill,
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        phone: profile?.phone,
        pin: profile?.pin ?? '',
        airmenName: profile?.airmenLicense?.airmenName ?? '',
        airmenNumber: profile?.airmenLicense?.airmenNumber ?? '',
        licenseType: profile?.airmenLicense?.licenseType ?? '',
      }));
  }, [profile]);
  return (
    <>
      <Toast {...toastProps} />
      <div className="flex flex-col lg:flex-row justify-center">
        <Formik
          enableReinitialize
          validationSchema={ProfileSchema}
          initialValues={formPrefill}
          onSubmit={async (values: typeof formPrefill) => {
            setIsLoadingSave(true);
            await updateProfile({
              variables: {
                input: {
                  id: user?.mroEmployeeProfileId,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  phone: values.phone,
                  pin: values.pin,
                  airmenLicense: {
                    airmenName: values.airmenName,
                    airmenNumber: values.airmenNumber,
                    licenseType: values.licenseType,
                  }
                },
              },
            });
            
            setTimeout(() => { 
              setIsLoadingSave(false);
              showToast({ title: 'Profile Updated', type:ToastLength.Normal });
            }, 1500);
          }}>
          <Form className="flex flex-col w-full lg:w-2/3 gap-2 p-10 rounded-l">
            <span className="font-bold text-xs text-brand uppercase mb-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">
              Profile Information
            </span>
            <div className="flex items-start">
              <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Name</span>
              <div className="flex flex-col w-2/3">
                <FullFormikInput name="firstName" placeholder="First Name" />
                <FullFormikInput name="lastName" placeholder="Last Name" />
              </div>
            </div>
            <div className="flex items-start">
              <span className="w-1/3 text-sm font-medium text-slate-500">Email</span>
              <div className="flex flex-col w-2/3">
                <FullFormikInput name="email" />
              </div>
            </div>
            <div className="flex">
              <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Phone</span>
              <div className="flex items-center w-2/3">
                <div className="rounded-l p-2 border border-r-0 w-9 font-semibold text-sm">+1</div>
                <Field name="phone" className="rounded-r border p-2 text-sm bg-slate-50 border-slate-300 focus:bg-white w-full" />
              </div>
            </div>
            <span className="font-bold text-xs text-brand uppercase my-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">
              Airmen License Information
            </span>
            <div className="flex items-center">
              <span className="w-1/3 text-sm font-medium text-slate-500">Airmen Name</span>
              <FullFormikInput name="airmenName" />
              </div>
              <div className="flex">
              <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Airmen Number</span>
              <FullFormikInput name="airmenNumber" />
              </div>
              <div className="flex items-center">
              <span className="w-1/3 text-sm font-medium text-slate-500">License Type</span>
              <FullFormikSelect name="licenseType">
                  <option value=''></option>
                  <option>A&P</option>
                  <option>A&P IA</option>
              </FullFormikSelect>
            </div>
            <span className="font-bold text-xs text-brand uppercase my-6 border-b border-slate-200 py-0.5 -mx-4 pl-4">Pin</span>
            <div className="flex mb-4">
              <span className="py-2 w-1/3 text-sm font-medium text-slate-500">Employee Pin</span>
              <div className="flex w-2/3 justify-end gap-2">
                <FullFormikInput name="pin" className=" min-w-[2rem] text-right" />
              </div>
            </div>    
            <Button text={isLoadingSave ? 'Saving...' : 'Save'}
                    color="navy"
                    size="sm"
                    type="submit"
                    disabled={isLoadingSave}
                    className='mt-5 disabled:opacity-70 w-full' />     
          </Form>
        </Formik>
        <div className="flex flex-col w-full lg:w-1/3 pt-14 p-4 bg-slate-50">
          <div className={`${!openColor && 'shadow-blue-lg'} flex rounded pb-0 select-none`}>
            <div className={`${openColor ? 'rounded-tl' : 'rounded-l'} flex flex-col items-center bg-white border border-slate-300 p-2 w-24`}>
              {profile?.profilePicture ? (
                <img className="w-12 h-12 rounded-full transition" alt="profile" src={profile?.profilePicture?.signedUrl}></img>
              ) : (
                <div
                  className={`flex items-center justify-center w-12 h-12 font-bold text-xl rounded-full text-white transition bg-${
                    pfColor ?? 'blue'
                  }-500`}>
                  {user?.firstName?.at(0)?.toUpperCase()}
                  {user?.lastName?.at(0)?.toUpperCase()}
                </div>
              )}
            </div>
            <div
              className={`${
                openColor ? 'rounded-tr' : 'rounded-r'
              } flex p-2 w-1/2 flex-auto bg-white border border-l-0 border-slate-300 gap-2`}>
              <div className="flex flex-col w-1/3">
                <div
                  onClick={() => {
                    setOpenColor(!openColor);
                  }}
                  className={`bg-${pfColor ?? 'blue'}-500 rounded h-7 mt-1 w-full relative hover:bg-opacity-50 transition cursor-pointer`}>
                  <PencilIcon className="h-3 w-3 absolute text-white top-1 right-1" />
                </div>
                <span className="font-bold text-xs text-slate-500 uppercase pt-1">Color</span>
              </div>
              <div className="flex flex-col w-2/3 flex-none mt-1">
                <form className="flex items-center gap-1">
                  <input id="pfpInput" type="file" onChange={(e) => setPfp(e.target.files[0])} className="hidden" />
                  <label
                    htmlFor="pfpInput"
                    className="border border-slate-200 rounded bg-white text-sm text-slate-500 font-semibold h-7 hover:opacity-80 transition text-center pt-0.5 px-2 cursor-pointer truncate flex-auto">
                    {pfp ? pfp.name : 'Upload'}
                  </label>
                  <TrashIcon className="border border-red-200 text-red-500 bg-red-50 w-7 h-7 p-1.5 rounded cursor-pointer hover:bg-red-500 hover:text-white transition flex-none" />
                </form>
                <span className="font-bold text-xs text-slate-500 uppercase pt-1">Picture</span>
              </div>
            </div>
          </div>
          <div className={`${openColor ? 'block' : 'hidden'}`}>
            <div className="flex flex-col items-center shadow-blue-lg w-auto p-2 bg-white rounded-b border border-t-0 border-slate-300">
              <div className="grid grid-rows-2 2xl:grid-rows-1 grid-flow-col gap-1 content-center justify-center">
                {colors.map((color, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        setPfColor(color);
                      }}
                      className={`w-8 h-8 lg:w-4 lg:h-4 cursor-pointer rounded bg-${color}-500`}></div>
                  );
                })}
              </div>
            </div>
          </div>
          <button
            className="self-end mt-2 bg-brand-pale text-brand-electric text-sm rounded font-semibold py-1.5 px-6 disabled:opacity-50 hover:opacity-95"
            onClick={handlePfp}>
            Save
          </button>
        </div>
      </div>
    </>
  );
}
