import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, from, HttpLink, FetchPolicy } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
const authLink = setContext(async (_, { headers }) => {
  // const accessToken = document.cookie.replace(
  //   /(?:(?:^|.*;\s*)authHeaderPayload\s*\=\s*([^;]*).*$)|^.*$/,
  //   '$1'
  // );
  const accessToken = localStorage.getItem('coflytAccessToken');
  let jwt: any;
  try {
    jwt = parseJwt(accessToken);
  } catch (e) {}
  let newAccessToken: string;
  if (!jwt || new Date().getTime() > jwt.exp * 1000 - 100) {
    const refreshToken = localStorage.getItem('coflytRefreshToken');
    if (!refreshToken)
      return {
        headers,
      };
    const res = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        query: `mutation {
      refreshToken(token: "${refreshToken}") {
        accessToken
        refreshToken
      }
    }
    `,
        variables: {},
      }),
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const data = (await res.json()).data;
    if (!data) return { headers };
    newAccessToken = data.refreshToken.accessToken;
    if (!newAccessToken) return { headers };
    localStorage.setItem('coflytAccessToken', newAccessToken.split('.').slice(0, 2).join('.'));
    localStorage.setItem('coflytRefreshToken', data.refreshToken.refreshToken);
    // const token = await getToken();
  }

  return { headers };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors && graphQLErrors.length > 0) console.error(graphQLErrors);
  if (networkError) console.error(networkError);
  return forward(operation);
});

const link = new HttpLink({
  uri: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + '/graphql' : 'http://localhost:4000/graphql',
  credentials: 'include',
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        mroWorkOrderPart: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'MroWorkOrderPart',
              id: args.id,
            });
          },
        },
        mroLaborEntry: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'MroLaborEntry',
              id: args.id,
            });
          },
        },
        mroWorkOrderItem: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: 'MroWorkOrderItem',
              id: args.id,
            });
          },
        },
      },
    },
  },
});



const gqlClient = new ApolloClient({
  cache, 
  link: from([errorLink, authLink, link]),
  defaultOptions:{
    watchQuery: {
      pollInterval: process.env.REACT_APP_APP_MODE === "testing" ? 90000 : 40000, // While testing, set to 15 minutes (cleaner logs), otherwise 40 seconds
    },
    query: {
      pollInterval: process.env.REACT_APP_APP_MODE === "testing" ? 90000 : 40000, // While testing, set to 15 minutes (cleaner logs), otherwise 40 seconds
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <IntercomProvider appId={ process.env.REACT_APP_APP_MODE === 'production' ? 'mkwoveci' : ''} apiBase='https://api-iam.intercom.io' autoBoot={true}>
      <ApolloProvider client={gqlClient}>
        <App />
      </ApolloProvider>
    </IntercomProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
