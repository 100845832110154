import { useState, useMemo, useContext, useEffect } from 'react';
import { Table } from 'components/Table/Table';
import { useQuery } from '@apollo/client';
import { GetMroPartsDocument, QueryMode } from 'graphql/generated';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { useSession } from 'contexts';
import Tabs from 'components/Tabs/Tabs';
import { useNavigate } from 'react-router-dom';
import { PartsContext } from '..';
import { StarIcon } from '@heroicons/react/24/solid';

const PartsTab = function ({ updateFlyout }: { updateFlyout: FlyoutHookReturn['updateFlyout'] }) {
  const [activeTab, setActiveTab] = useState('In Stock');
  const tabs = ['In Stock', 'Out Of Stock', 'All'];
  const navigate = useNavigate();
  const { user } = useSession();
  const [ limit, setLimit ] = useState(10);
  const [ skip, setSkip ] = useState(0);
  const { filter, partsTab } = useContext(PartsContext); 
  useEffect(() => partsTab && setActiveTab(partsTab), [partsTab]);

  const { data: { mroParts = [], mroPartCount } = {} } = useQuery(GetMroPartsDocument, {
    variables: { 
      where: { 
        mroOrganizationId: { equals: user.mroOrganizationId },
        AND: [
          { OR: [
            { partNumber: { contains: filter, mode: QueryMode.Insensitive } },
            { description: { contains: filter, mode: QueryMode.Insensitive } },
          ] },
          {
            ...(activeTab === 'In Stock' && { 
              OR: [
                
                { isSerialized: { equals: false }, mroPartInventories: { some: { quantity: { gt: 0 } } } },
                { isSerialized: { equals: true }, mroSerializedParts: { some: { isAvailable: { equals: true } } } },
              ]
            }), 
            ...(activeTab === 'Out Of Stock' && { 
              OR: [
                { isSerialized: { equals: false }, mroPartInventories: { none: { quantity: { gt: 0 } } } },
                { isSerialized: { equals: true }, mroSerializedParts: { none: { isAvailable: { equals: true } } } },
              ]
            })
          }
        ],
      },
      limit,
      skip
    },
  });

  
  useEffect(() => {
    setSkip(0);
  }, [activeTab, filter]);



  const columns = useMemo(
    () => [
      {
        Header: 'Part #',
        accessor: 'partNumber', // accessor is the "key" in the data
        className: 'w-1/4',
        Cell: ({ value, row }: any) => {
          return <div className='bg-slate-100 flex flex-row items-center rounded px-2 py-1 gap-1'>
            <span className="font-bold text-brand-dark">{value}</span>
            {row.original.preferred && <StarIcon className="h-4 w-4 mt-[-2px] flex-shrink-0" />}
          </div>;
        },
      },
      {
        Header: 'Description',
        accessor: 'description', // accessor is the "key" in the data
        className: 'w-2/4 items-start',
        Cell: ({ value, row }: any) => {
          return <span className="py-1">{value}</span>;
        },
      },
      {
        Header: 'Serialized',
        accessor: 'isSerialized', // accessor is the "key" in the data
        className: 'w-1/6 items-center font-bold uppercase',
        Cell: ({ value, row }: any) => {
          return <span className="py-1 ml-5">{value ? 'Yes' : 'No'}</span>;
        },
      },
      {
        Header: 'Quantity',
        accessor: (inv) => {
          return {
            mroPartInventories: inv?.mroPartInventories,
            mroSerializedParts: inv?.mroSerializedParts,
          };
        }, // accessor is the "key" in the data
        className: 'w-1/6 items-start',
        Cell: ({ value, row }: any) => {
          if (value.mroSerializedParts?.length > 0) {
            return (
              <span className="py-1">
                {'x' +
                  value.mroSerializedParts.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.isAvailable ? 1 : 0);
                  }, 0)}
              </span>
            );
          } else if (value.mroPartInventories?.length > 0) {
            return (
              <span className="py-1">
                {'x' +
                  value.mroPartInventories.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.quantity;
                  }, 0)}
              </span>
            );
          }
          return <span className="py-1">N/A</span>;
        },
      },
    ],
    []
  );
  return (
    <>
      <div className="flex flex-col justify-between items-start border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2 mb-3">
        <div className="flex justify-between w-full">
          <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
        </div>
      </div>
      <Table columns={columns} data={mroParts} count={mroPartCount} skip={skip} limit={limit}
        updateLimitAndSkip = {(lm, sk) => { setLimit(lm); setSkip(sk); }}
        onRowClick={(obj) => navigate(obj.id)} />
    </>
  );
};

export default PartsTab;
