import Button from "components/Button/Button";
import { useContext } from "react";
import { WalkthroughContext } from "..";

export default function SelectInspections() {
    const { markAsComplete, changeStep } = useContext(WalkthroughContext);
    return (
        <>
            <div className="absolute inset-0 top-0 bg-black/50 z-[100] flex items-start pt-20 justify-center px-2" onClick={(e) => e.stopPropagation()}>
                <div className="bg-white min-h-44 min-w-36 p-4 rounded flex flex-col justify-around text-center">
                    <span className=''>Next lets step up our first inspection together, click the "Status" section to view our premade inspections</span>
                    <div className="flex gap-4 justify-center mt-4">
                        <Button text="Close Setup" color="red" size="xs" onClick={() => markAsComplete()} />
                    </div>
                </div>
            </div>
        </>
    )
}