import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetMroPartDocument,
  GetMroPartVariantsDocument,
  UpdateMroPartDocument, 
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal/Modal';
import { StarIcon } from '@heroicons/react/24/solid';

const ViewVariant = function ({ partId, variantLocation, connectedPartId, closeFlyout }: 
  { partId: string; connectedPartId:string; variantLocation: string; closeFlyout: () => void }) {

  const { data: { mroPart } = {} } = useQuery(GetMroPartDocument, { variables: { partId } });
  const [updatePart] = useMutation(UpdateMroPartDocument, {
    refetchQueries: [{ query: GetMroPartVariantsDocument, variables: { id: connectedPartId } }],
    onCompleted: () => {
      showToast({ title: 'Part Updated', type: ToastLength.Normal });
    }
  });
  const { toastProps, showToast } = useToast();
  const [ showModal, setShowModal ] = useState(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    variantLocation,
  });


  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      updatePart({
        variables: {
          input:{
            id: connectedPartId,
            ...(values.variantLocation === "Both" && { variantsLeft: { connect: [ {id: partId} ] }, variantsRight: { connect: [ {id: partId} ] } } ),
            ...(values.variantLocation === "Left" && { variantsLeft: { connect: [ {id: partId} ] }, variantsRight: { disconnect: [ {id: partId} ] } } ),
            ...(values.variantLocation === "Right" && { variantsRight: { connect: [ {id: partId} ] }, variantsLeft: { disconnect: [ {id: partId} ] } } ),
          }
        },
      });
    },
    [variantLocation]
  );

  return (
    <div className="p-4">
      {showModal && <Modal message={"You are about to disconnect this part from the variant"} 
        cancel={setShowModal} accept={async ()=> { 
          await updatePart({ variables:{ input:{ id: connectedPartId, variantsLeft: { disconnect: [ {id: partId} ] }, variantsRight: { disconnect: [ {id: partId} ]  } } } });
          closeFlyout();
        }} />}
      <Toast {...toastProps} position='top' />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ values }) => (
          <Form>
            {/* PART INFORMATION */}
            <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
              <div className='flex flex-col'>
                <div className='flex flex-row'>
                  <span className='font-bold text-lg'>{mroPart?.partNumber}</span>
                  {mroPart?.preferred && <StarIcon className='h-6 w-6' />}
                </div>
                <span>{mroPart?.description}</span>
              </div>
              <div className='flex flex-row gap-4 justify-center'>
                <FullFormikSelect name="variantLocation" label="Variant Location">
                  <option value="Left">Left</option>
                  <option value="Right">Right</option>
                  <option value="Both">Both</option>
                </FullFormikSelect>
                <Button text="Save" color="pale" size="xs" type="submit" />  
              </div>
            </div>
            <div className="flex justify-end items-center mt-4 gap-5">
              <Button text="Disconnect Part" color="red" size="sm" onClick={() => setShowModal(true)} />
              <Button text="View Part" color="navy" size="sm" onClick={() => { 
                navigate("/mro/parts/"+partId);
                closeFlyout();
              }} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ViewVariant;
