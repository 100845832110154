import Button from "components/Button/Button";
import { useContext } from "react";
import { WalkthroughContext } from "..";

export default function InspectionPageOverview() {
    const { markAsComplete, changeStep } = useContext(WalkthroughContext);
    const nextStep = () => {
        changeStep('inspectionPageOverview', 'selectAnnualInspection', false);
    };
    return (
        <>
            <div className="absolute inset-0 bottom-0 bg-black/50 z-[100] flex items-end justify-center pb-36 px-2" onClick={(e) => e.stopPropagation()}>
                <div className="bg-white min-h-44 min-w-36 p-4 rounded flex flex-col justify-around text-center">
                    <span className=''>This is Inspection/Status page, this is where you create and log all your aircraft inspections</span>
                    
                    <div className="flex gap-4 justify-center mt-4">
                        <Button text="Close Setup" color="red" size="xs" onClick={() => markAsComplete()} />
                        <Button text="Next" color="brand" size="sm" onClick={nextStep} />
                    </div>
                </div>
            </div>
        </>
    )
}