import Button from "components/Button/Button";
import { useContext } from "react";
import { WalkthroughContext } from "..";

export default function SetupAnnualInspection() {
    const { markAsComplete, changeStep } = useContext(WalkthroughContext);

    return (
        <>
            <div className="fixed inset-0 bg-black/50 z-[100] flex items-start justify-center h-32 pt-10 px-2" onClick={(e) => e.stopPropagation()}>
                    <div className="bg-white min-h-44 min-w-36 p-4 rounded flex flex-col justify-around text-center">
                        <span className=''>We can now log a new Inspection Entry from this flyout, please fill out when you performed your last Annual</span>
                        <span className='text-sm'>You can also add any notes you would like</span>
                        <div className="flex gap-4 justify-center mt-4">
                            <Button text="Close Setup" color="red" size="xs" onClick={() => markAsComplete()} />
                        </div>
                    </div>
            </div>
            <div className="fixed inset-0 bg-black/50 z-[50] flex items-start justify-center pt-10 px-2" onClick={(e) => e.stopPropagation()}>
               
            </div>
        </>
    )
}