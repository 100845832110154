import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { FullFormikInput, FullFormikSelect, FullFormikTextarea } from "components/Form/StandardForm";
import { Table } from "components/Table/Table";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";
import { useSession } from "contexts";
import { Formik, Form } from "formik";
import { GetMroOrganizationDocument, GetMroOrganizationIconsDocument, GetMroOrganizationInfoDocument, UpdateMroOrganizationDocument, UpdateOrganizationDocument, UpdateOrganizationIconDocument } from "graphql/generated";
import { useRef, useState } from "react";
import LocationList from "./Locations/LocationList";
import FeesList from "./Fees/FeesList";

const templateTypes = {
    "LOG_ENTRY" : "DEFAULT",
    "ESTIMATE" : "DEFAULT",
    "INVOICE" : "DEFAULT",
    "PART_ORDERS" : "DEFAULT",
};

export default function OrganizationSettings() {
    const { user } = useSession();
    const { updateFlyout, flyoutProps, closeFlyout } = useFlyout();
    const [colorLogo, setColorLogo] = useState<File>(null);
    const [blackLogo, setBlackLogo] = useState<File>(null);
    const blackLogoRef = useRef(null);
    const colorLogoRef = useRef(null);
    const [updateOrganizationIcon] = useMutation(UpdateOrganizationIconDocument);
    const [updateOrganization] = useMutation(UpdateMroOrganizationDocument, { refetchQueries: [GetMroOrganizationInfoDocument] });
    const { refetch, data: { mroOrganization: { mroBlackAndWhiteIcon, mroColorIcon } } = { mroOrganization : { mroBlackAndWhiteIcon: undefined, mroColorIcon: undefined }  } } = useQuery(GetMroOrganizationIconsDocument, { variables: { id: user?.mroOrganizationId } });
    const { data : { mroOrganization } = {} } = useQuery(GetMroOrganizationDocument, { variables: { id: user?.mroOrganizationId } });
    const { showToast, toastProps } = useToast();
    const formPrefill = {
        name: mroOrganization?.name,
        phone: mroOrganization?.address?.phone,
        fax: mroOrganization?.address?.fax,
        address: mroOrganization?.address?.address,
        city: mroOrganization?.address?.city,
        region: mroOrganization?.address?.region,
        postalCode: mroOrganization?.address?.postalCode,
        defaultBillableRate: mroOrganization?.defaultBillableRate,
        defaultCostRate: mroOrganization?.defaultCostRate,
        estimateAuthorization: mroOrganization?.estimateAuthorization,
    };
    const handleLogoSave = async() => {
        let colorIconId = undefined;
        let blackIconId = undefined;
        if(colorLogo){
            const colorData = new FormData();
            colorData.append('files', colorLogo);
            const resColor = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: colorData });
            const bodyColor = await resColor.json();
            colorIconId = bodyColor[0].id;
        }

        if(blackLogo) {
            const blackData = new FormData();
            blackData.append('files', blackLogo);
            const resBlack = await fetch(process.env.REACT_APP_API_URL + '/attachments/upload', { method: 'POST', body: blackData });
            const bodyBlack = await resBlack.json();
            blackIconId = bodyBlack[0].id;
        }
        await updateOrganizationIcon({
            variables : {
                organizationId: user.mroOrganizationId,
                colorIconId,
                blackIconId,
            }
        });
        refetch();
        blackLogoRef.current.value = '';
        colorLogoRef.current.value = '';
    };    
    return (
        <>
            <Toast position="top" {...toastProps} />
            <Flyout {...flyoutProps} />
            <Formik
            enableReinitialize
            initialValues={formPrefill}
            onSubmit={async (values: typeof formPrefill) => {
                await updateOrganization({ variables: { input: { id: user.mroOrganizationId, name: values.name,
                    defaultCostRate: values.defaultCostRate,
                    defaultBillableRate: values.defaultBillableRate,
                    estimateAuthorization: values.estimateAuthorization,
                    address: { 
                        update: {
                            data:{
                                phone: values.phone,
                                fax: values.fax,
                                address: values.address,
                                city: values.city,
                                region: values.region,
                                postalCode: values.postalCode,
                            }
                        }
                    } 
                } } });
                showToast({ type: ToastLength.Normal, title: 'Organization Info Updated' });
            }}>
                <Form className="flex flex-col w-full gap-2 rounded-l">
                    <Card title="Organization Info">
                        <div className="flex flex-col items-start">
                            <FullFormikInput name="name" placeholder="Organization Name" label="Name" />
                            <div className="flex gap-2 w-full">
                                <FullFormikInput name="phone" placeholder="1234567890" label="Phone" />
                                <FullFormikInput name="fax" placeholder="1234567890" label="Fax" />
                            </div>
                            <FullFormikInput name="address" placeholder="101 Test Street" label="Address" />
                            <div className="flex gap-2 w-full">
                                <FullFormikInput name="city" placeholder="New York City" label="City" />
                                <FullFormikInput name="region" placeholder="NY" label="State" />
                                <FullFormikInput name="postalCode" placeholder="54321" label="Zip Code" />
                            </div>
                            <div className="flex gap-2 w-full">
                                <FullFormikInput name="defaultBillableRate" placeholder="99.99" label="Billable Rate" />
                                <FullFormikInput name="defaultCostRate" placeholder="50.99" label="Cost Rate" />
                            </div>
                            <FullFormikTextarea name="estimateAuthorization" placeholder="Insert Maintenance Authorization" label="Maintenance Authorization" />
                        </div>
                        <div className="flex justify-end">
                            <Button text="Save" color="navy" size="xs" type="submit" />
                        </div>
                    </Card>
                    <LocationList updateFlyout={updateFlyout} closeFlyout={closeFlyout} />
                    <FeesList updateFlyout={updateFlyout} closeFlyout={closeFlyout} />
                    <Card title="Organization Logos">
                        <div className="flex flex-col gap-4 mt-4">
                            <div className="flex items-center gap-2 border-b-2 py-3">
                                <label className="w-1/6">Color Logo</label>
                                <input type="file" ref={colorLogoRef} accept="image/png, image/jpeg" onChange={(e) => setColorLogo(e.target.files[0])} />
                                { mroColorIcon?.signedUrl && <img src={mroColorIcon?.signedUrl} alt="Color Logo" className="w-20" /> }
                            </div>
                            <div className="flex items-center gap-2">
                                <label className="w-1/6">Black Logo</label>
                                <input type="file" ref={blackLogoRef} accept="image/png, image/jpeg" onChange={(e) => setBlackLogo(e.target.files[0])} />
                                { mroBlackAndWhiteIcon?.signedUrl && <img src={mroBlackAndWhiteIcon?.signedUrl} alt="Black Logo" className="w-20" /> }
                            </div>

                            <div className="flex justify-end">
                                <Button text="Save" color="navy" size="xs" onClick={handleLogoSave} />
                            </div>
                        </div>
                    </Card>
                    <Card title="Organization PDF Template" subtitle="Available PDF Options (Contact Us for Custom)">
                        <div className="flex flex-col gap-4 mt-4">
                            <FullFormikSelect name={"LOG_ENTRY"} label="Log Entry Template" >
                                <option value={"DEFAULT"} >Default</option>
                            </FullFormikSelect>
                            <FullFormikSelect name={"ESTIMATE"} label="Estimate Template" >
                                <option value={"DEFAULT"} >Default</option>
                            </FullFormikSelect>
                            <FullFormikSelect name={"INVOICE"} label="Invoice Template" >
                                <option value={"DEFAULT"} >Default</option>
                            </FullFormikSelect> 
                            <FullFormikSelect name={"PART_ORDERS"} label="PO Template" >
                                <option value={"DEFAULT"} >Default</option>
                            </FullFormikSelect> 
                            <Button text="Save" color="navy" size="sm" type="submit" className="disabled:opacity-50" />
                        </div>
                    </Card>
                </Form>
            </Formik>
        </>
    )
}