import { createContext, useContext, useEffect, useState } from 'react';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CogIcon } from '@heroicons/react/24/solid';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import Requests from './Requests';
import Orders from './Orders';
import PartsTab from './Components/PartsTab';
import NewPart from './New';
import NewPartOrder from './Orders/New';
import TabCards, { TabCardItem } from 'components/TabCards/TabCards';
import useSaveState from 'hooks/useSaveState';
import { AbilityContext, Can } from 'contexts/AbilityContext';
interface PartsContextInterface {
  filter: string | undefined;
  setFilter: React.Dispatch<React.SetStateAction<string>>; 
  partsTab: string | undefined;
}

export const PartsContext = createContext<PartsContextInterface | null>(null);

const Parts = function (props) {
  const ability = useContext(AbilityContext);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [filter, setFilter] = useState<PartsContextInterface['filter']>('');
  const tabsStyle = 'p-6 bg-white border border-slate-300 max-w-[calc(78rem-2px)] w-full rounded-b rounded-r shadow-blue';
  const tabs : TabCardItem[] = [
    {
      title: 'Parts',
      content: (
        <div className={tabsStyle}>
          <PartsTab updateFlyout={updateFlyout} />
        </div>
      ),
    },
    {
      title: 'Requests',
      content: (
        <div className={tabsStyle}>
          <Requests updateFlyout={updateFlyout} />
        </div>
      ),
    },
    {
      title: 'Orders',
      content: (
        <div className={tabsStyle}>
          <Orders {...{ closeFlyout, updateFlyout }} />
        </div>
      ),
      hidden: ability.cannot('Manage', 'Part Orders')
    },
  ];
  const [currentTab, setCurrentTab] = useSaveState('partsCardTab', tabs[0].title);

  const [partsTab, setPartsTab] = useState(null);
  const contextValue = {
    filter,
    setFilter,
    partsTab
  }

  return (
    <div className='pb-20 md:pb-0'>
      <PartsContext.Provider value={contextValue}>
        <Flyout {...flyoutProps} />
        <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded shadow-blue">
          <div className="flex items-center">
            <CogIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-0.5" />
            <h1 className="text-left font-bold text-brand-dark text-2xl md:text-2xl">Parts</h1>
          </div>
          <div className="flex items-stretch gap-2 flex-wrap justify-end">
            <div className="relative flex items-center">
              <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
              <input
                className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
                placeholder="Search"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
            </div>
            {currentTab === 'Parts' && (
              <Can I="Create" a="Parts">
                <div
                  onClick={() => {
                    updateFlyout({ title: 'Create Part', content: <NewPart closeFlyout={(didComplete?:boolean)=>{
                      if(didComplete) setPartsTab('All'); setTimeout(() => setPartsTab(null), 2000);
                      closeFlyout();
                    }} /> });
                  }}
                  className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4 wrap">
                  <PlusIcon className="h-3 w-3 mr-1" />
                  New Part
                </div>
              </Can>
            )}
            {currentTab === 'Orders' && (
              <Can I="Create" a="Part Orders">
                <div
                  onClick={() => {
                    updateFlyout({ title: 'Create Order', content: <NewPartOrder closeFlyout={closeFlyout} /> });
                  }}
                  className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
                  <PlusIcon className="h-3 w-3 mr-1" />
                  New Order
                </div>
              </Can>
            )}
          </div>
        </div>
        <div className="my-2">
          <TabCards items={tabs} 
              activeTitle={currentTab}
              setActiveTitle={setCurrentTab} 
              addBars={false} />
        </div>
      </PartsContext.Provider>
    </div>
  );
};

export default Parts;
