import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { GetAdsQuery } from "graphql/generated";
import { useState } from "react";
import { formatApiDate } from "utils/formatter";
import { AD } from "./ADSFlyout";


const ADItem = ({ ad, index, toggleKey } : 
    {   index:number;
        ad: AD;
        toggleKey: (index: number, key: string) => void;} ) => {
    const [ isExpanded, setIsExpanded ] = useState(false);
    return (
        <div className="flex justify-between items-center gap-5 border-b-4 last:border-none -mx-3 w-full" >
            <div className="w-1/12 h-full">
                <input type="checkbox" className="ml-3 w-7 h-7" checked={ad.isSelected} onChange={() => toggleKey(index, 'isSelected')} />
            </div>
            <div className="flex flex-col w-9/12 cursor-pointer text-center" onClick={() => setIsExpanded(!isExpanded)}>
                <span className={`text-lg font-bold text-brand-dark ${!isExpanded && 'truncate'}`}>{`${ad.adNumber.split("/")[1]} - ${ad.subject ?? ad?.title ?? 'Unknown'}`}</span>
                <div className="flex justify-center items-center">
                    <span className="font-semibold text-brand-dark">Effective Date {formatApiDate(ad.effectiveDate)}</span>
                    <ChevronDownIcon className={`h-4 w-4 ml-1 ${isExpanded ? 'transform rotate-180' : ''}`} />
                </div>
                <span className={`text-brand-electric font-medium text-sm ${!isExpanded && 'truncate'}`}>{ad.description}</span>
            </div>
            <div className="w-1/12">
                <input type="checkbox" className="w-7 h-7" checked={ad.isRecurring} onChange={() =>toggleKey(index, 'isRecurring')} />
            </div>
        </div>
    );
}


export default ADItem;