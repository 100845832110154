import { Formik, Form } from 'formik';
import { FullFormikInput, FullFormikSelect } from 'components/Form/StandardForm';
import Button from 'components/Button/Button';
import { useMutation, useQuery } from '@apollo/client';
import {
  GetMroPartDocument,
  GetMroPartInventoriesDocument,
  GetMroPartInventoryDocument,
  GetMroPartVariantsDocument,
  GetMroPartWarehouseDocument,
  GetMroPartsDocument,
  MroPart,
  PartCondition,
  UpdateMroPartDocument,
  UpdateMroPartInventoryDocument,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { useSession } from 'contexts';
import PartLookup, { PartNumberLookup } from 'views/Part145/Parts/Components/PartLookup';

const LinkNewVariant = function ({ partId, variantLocation, closeFlyout }: { partId: string; variantLocation: string; closeFlyout: () => void }) {
    const [updatePart] = useMutation(UpdateMroPartDocument, { 
        refetchQueries: [
            { query: GetMroPartVariantsDocument, variables: { id: partId } },
        ],
        onCompleted: () => closeFlyout()
    });

    const [initialValues, setInitialValues] = useState({
        variantLocation,
    });

    const [selectedPart, setSelectedPart] = useState(null as MroPart | null);
    const handleSubmit = useCallback(
        (values: typeof initialValues) => {
            updatePart({
                variables:{
                    input:{
                        id: partId,
                        variantsLeft: {
                            connect: selectedPart && (values.variantLocation !== "Right") ? [{ id: selectedPart.id }] : []
                        },
                        variantsRight: {
                            connect: selectedPart && (values.variantLocation !== "Left") ? [{ id: selectedPart.id }] : []
                        }
                    }
                }
            });
        },
        [variantLocation, selectedPart]
    );
    return (
        <div className="p-4">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {({ values }) => (
            <Form>
                {/* PART INFORMATION */}
                <div className="flex flex-col w-full border border-slate-300 rounded p-10 bg-white">
                    <div className='flex flex-col'>
                        <PartLookup {...{selectedPart, setSelectedPart, removeIds: [ partId ]}} />
                    </div>
                    <div className='flex flex-col'>
                        <FullFormikSelect name="variantLocation" label='Variant Postion'>
                            <option value='Left'>Left</option>
                            <option value='Right'>Right</option>
                            <option value='Both'>Both</option>
                        </FullFormikSelect>
                    </div>
                </div>
                <div className="flex justify-end items-center mt-4">
                    <Button text="Link Part" color="navy" size="sm" type="submit" />
                </div>
            </Form>
            )}
        </Formik>
        </div>
    );
};

export default LinkNewVariant;
