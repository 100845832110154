import { useMutation, useQuery } from "@apollo/client";
import { LockClosedIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import Flyout, { useFlyout } from "components/Flyout/Flyout";
import { Table } from "components/Table/Table";
import { useSession } from "contexts";
import { GetIntervalsDocument, IntervalType, RemoveIntervalDocument } from "graphql/generated";
import { useMemo } from "react";
import New from "./New";
import { CheckCircleIcon, CheckIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Edit from "./Edit";
import Toast, { ToastLength, useToast } from "components/Toast/Toast";


export default function Intervals(){
    const { craftId } = useSession();
    const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
    const { loading, error, data, refetch } = useQuery(GetIntervalsDocument, { variables: { craftId } });
    const { toastProps, showToast } = useToast();
    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                id: 'name',
                accessor: 'name', // accessor is the "key" in the data
                className: 'w-3/6 items-center pr-2',
            },
            {
                Header: 'Cust.',
                id: 'isCustom',
                accessor: 'isCustom', // accessor is the "key" in the data
                className: 'w-1/6 items-center justify-center',
                Cell: ({ value, row }: any) => {
                    return value ? <CheckIcon className="w-4 h-4"/> : <XMarkIcon className="w-4 h-4"/>;
                },
            },
            {
              Header: 'Value',
              id: 'recentIntervalLog',
              accessor: (row: any) => row.recentIntervalLog,
              className: 'w-2/6 items-center justify-center',
              Cell: ({ value, row }: any) => {
                return( 
                <div className="flex gap-2 mr-[-25px]">
                  <span>{value?.endValue ?? value?.startValue ?? '0'}</span>
                  {!row.original.isCustom ? 
                    <LockClosedIcon className="w-4 h-4" /> : 
                    <span className="w-4 h-4">{"\xa0"}</span>}
                </div>);
              },
          },
        ], []
    );
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    return (
      <>
        <Toast {...toastProps} position="top" />
        <Flyout {...flyoutProps} />
        <Card className="px-2 md:px-8 py-3 m-3 rounded bg-white">
          { data.intervals && data.intervals.length > 0 ? 
              <Table columns={columns} 
              data={[...data?.intervals ?? []].filter((value) => 
                value.intervalType === IntervalType.Normal )
                .sort(
                  (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                ) ?? []} 
              onRowClick={(row) => row.isCustom ? updateFlyout({ title: row.name, content: <Edit id={row.id} closeFlyout={closeFlyout} /> }) : showToast({ title: "Cannot Edit Non-Custom Intervals", type: ToastLength.Normal } ) } />
            : <div className="text-center mt-4">No Intervals found</div>
          }
        </Card>
        <div className='flex justify-end px-3 py-4'>
          <Button text="Add Interval" color="navy" size="xs" icon={<PlusIcon className="h-4 w-4 mr-2" />} 
            onClick={() => updateFlyout({title: "New Interval", content: <New closeFlyout={()=>{
              refetch();
              closeFlyout();
            }} /> })} />
        </div>      
      </>
    );
}