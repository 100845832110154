import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {   
  FindOrganizationAirsyncTailsDocument,
  SetupAirsyncTailsDocument,
  RemoveAirsyncTailDocument,
  SyncAirSyncLogsDocument
} from 'graphql/generated';
import { Formik, Form } from 'formik';
import { useSession } from 'contexts';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { ArrowPathIcon, CheckBadgeIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import Toast, { ToastLength, useToast } from 'components/Toast/Toast';

const AirSyncSetup = function ({ closeFlyout }: { closeFlyout: () => void }) {
    const { organizationId } = useSession();
    const [ craftsToSync, setCraftsToSync ] = useState<{craftId: string, airSyncId: string}[]>([]);
    const { data : { findOrganizationAirsyncTails: crafts } = { }, loading } = useQuery( FindOrganizationAirsyncTailsDocument, { variables: { organizationId } });
    const [setupAirsyncTails] = useMutation(SetupAirsyncTailsDocument, { refetchQueries: [{ query: FindOrganizationAirsyncTailsDocument, variables: { organizationId } }] });
    const [removeAirsyncTail] = useMutation(RemoveAirsyncTailDocument, { refetchQueries: [{ query: FindOrganizationAirsyncTailsDocument, variables: { organizationId } }] });
    const [syncAirSync] = useMutation(SyncAirSyncLogsDocument, { refetchQueries: [{ query: FindOrganizationAirsyncTailsDocument, variables: { organizationId } }] });
    const [loadingSync, setLoadingSync] = useState(false);
    const { toastProps, showToast } = useToast();
    return (
    <>
        <Toast {...toastProps} />
        <Formik enableReinitialize
        initialValues={{ name : ''}}
        onSubmit={async (values) => {
            await setupAirsyncTails({ variables: { input: craftsToSync } });
        } }>
        {({ isSubmitting }) => {
            return (
                <Form>
                    { crafts?.length > 0 ? <>
                            <Card className="flex flex-col p-4 h-full m-4">
                                <div className='flex justify-start mt-3 mb-4'>
                                    {crafts?.map((craft) => {
                                        return (<div onClick={() => { 
                                                    if(craft.isSetup) return;
                                                    if(craftsToSync?.find((c) => craft.craftId === c.craftId) ){
                                                        setCraftsToSync(craftsToSync.filter((c) => c.craftId !== craft.craftId));
                                                    }else{
                                                        setCraftsToSync([ ...craftsToSync, { craftId: craft.craftId, airSyncId: craft.airSyncId } ]);
                                                    }
                                                }} key={craft.tailNumber} className='border-b w-full last:border-none'>
                                            <div className='flex gap-3 items-center'>
                                                { !craft.isSetup && <input checked={!!craftsToSync?.find((c) => craft.craftId === c.craftId)} className='form-checkbox w-4 h-4' type='checkbox' />}
                                                <label className='ml-2'>{craft?.tailNumber}</label>
                                                { craft.isSetup && <TrashIcon onClick={() => removeAirsyncTail({ variables: { input: { airSyncId: craft.airSyncId, craftId: craft.craftId } } })} 
                                                    className='text-rose-500 bg-white w-12 h-6 p-0.5 border border-rose-200 rounded cursor-pointer hover:bg-rose-50'/> }
                                            </div>
                                            { craft.isSetup && 
                                                <div className='flex mt-1'>
                                                    <span className='ml-2 text-sm text-gray-400'>Synced</span>
                                                    <CheckBadgeIcon className='w-5 h-5 ml-2 text-green-500'/>
                                                </div>
                                            }
                                            { craft.isSetup && 
                                                <div className='mt-2'>
                                                    <Button text="Pull Logs" onClick={()=>{
                                                        setLoadingSync(true);
                                                        syncAirSync({ variables: { input: { airSyncId: craft.airSyncId, craftId: craft.craftId } } }).then((result) => {
                                                            setLoadingSync(false);
                                                            if(result.data?.syncAirsyncLogs?.status === 'success'){
                                                                showToast({ title: 'Logs Synced', type: ToastLength.Normal });
                                                            }else{
                                                                showToast({ title: 'Error Syncing Logs', type: ToastLength.Normal });
                                                            }
                                                        });
                                                    }} disabled={loadingSync} size='xs' color='navy' icon={<ArrowPathIcon className='w-4 h-4 mr-2'/>} />
                                                </div>
                                            }
                                        </div>);
                                    })}
                                </div>
                            </Card> 
                            <div className="bg-gray-50 px-4 py-5 flex items-center justify-end">
                                <Button text="Sync" type='submit' disabled={isSubmitting || craftsToSync.length === 0} size='xs' color='navy' icon={<ArrowPathIcon className='w-4 h-4 mr-2'/>} />
                            </div>
                        </> : 
                        <div className="flex flex-col p-4 h-full m-4">
                            <span className="text-sm">{loading ? 'loading...' :  'No AirSync compatible Aircraft found'}</span>
                        </div>
                    }
                </Form>
        )}}
        </Formik>
    </>
    );
};

export default AirSyncSetup;
