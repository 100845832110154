import { useMutation, useQuery } from '@apollo/client';
import {  
  InspectionDueType,
  GetMaintenanceRecordDocument,
  DeleteMaintenanceRecordDocument,
  UpdateMaintenanceRecordDocument,
  GetInspectionDocument,
} from 'graphql/generated';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { useSession } from 'contexts';
import Button from 'components/Button/Button';
import Card from 'components/Card/Card';
import { FormLabel, FullFormikInput, FullFormikTextarea } from 'components/Form/StandardForm';
import { useEffect, useState } from 'react';
import Modal from 'components/Modal/Modal';
import DatePicker from 'components/DatePicker/DatePicker';
import { formatApiDate, formatApiDateUTC } from 'utils/formatter';

const EditEntryFlyout = function ( { closeFlyout, id, inspection } : { closeFlyout: () => void; id: string; inspection:any } ) {
    const { craftId } = useSession();
    const [ modal, setModal ] = useState(false);
    const { data: { maintenanceRecord } = {}, error } = useQuery( GetMaintenanceRecordDocument, { variables: { id }, pollInterval: 0 });
    const [ updateMaintenanceRecord ] = useMutation( UpdateMaintenanceRecordDocument, { refetchQueries: [ { query: GetInspectionDocument, variables: { id: maintenanceRecord?.newestInspection?.id } } ] } );
    const [ deleteMaintenanceRecord ] = useMutation( DeleteMaintenanceRecordDocument, { variables: { id }, 
        refetchQueries: [ { query: GetInspectionDocument, variables: { id: maintenanceRecord?.newestInspection?.id } } ],
        onCompleted: () => closeFlyout() } ); 
    const intervalName = inspection?.dueInterval ? Object.keys(inspection?.dueInterval)[0] : '';
    const [ initialValues, setInitialValues ] = useState({
        lastPerformedInterval: '',
        datePerformed: '',
        description: '',
    }); 

    useEffect(() => {
        if(maintenanceRecord){
            setInitialValues({
                lastPerformedInterval: maintenanceRecord?.intervalValues ? maintenanceRecord?.intervalValues[Object.keys(maintenanceRecord?.intervalValues)[0]] : { name: '', value: '' },
                datePerformed: formatApiDateUTC(maintenanceRecord?.completionDate),
                description: maintenanceRecord.description,
            });
        };
    }, [maintenanceRecord]);

  const handleSubmit = async (values: typeof initialValues, { setSubmitting }: FormikHelpers<typeof initialValues>) => {
    await updateMaintenanceRecord({
        variables: {
            input: {
                id,
                intervalValues: {
                    [intervalName ?? '']: values.lastPerformedInterval,
                },
                craft: { connect: { id: craftId } },
                completionDate: values.datePerformed,
                description: values.description,
            },
        },

    });
    setSubmitting(false);
    closeFlyout();
  };
  return (
    <>
        {modal && <Modal message="Are sure you want to delete this Maintenance Record?" cancel={setModal} accept={ () => deleteMaintenanceRecord() } />}
        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
            <Form className='p-2'>
            <Card>
                <div className="flex w-full items-center mb-2 gap-4">
                    <div className='flex flex-col w-1/2'>
                            <FormLabel className='mt-[-10px] text-sm font-medium text-brand'>Date Performed:</FormLabel>
                            <Field as={DatePicker} className="w-full" name="datePerformed"  />
                        </div>
                        { inspection?.dueMethod !== InspectionDueType.Date && <FullFormikInput name="lastPerformedInterval" label={`Last ${maintenanceRecord?.newestInspection?.newestInterval?.name} Performed:`} autoComplete="off" className='flex flex-col w-1/2' /> }
                    </div>
                    <FullFormikTextarea name="description" label="Notes" autoComplete="off" className='flex flex-col w-full mb-2' />
            </Card>
            <div className="px-4 mt-4 flex items-center justify-end gap-4">
                <Button text="Delete" color="red" size="xs" type='button' onClick={() => setModal(true)} disabled={isSubmitting} />
                <Button text="Save" color="navy" size="xs" type='submit' disabled={isSubmitting} />
            </div>
            </Form>
        )}
        </Formik>
    </>
  );
};

export default EditEntryFlyout;
