import Card from "components/Card/Card";
import { Table } from "components/Table/Table";
import { useMemo } from "react";
import { formatApiDate } from "utils/formatter";
import { useQuery } from "@apollo/client";
import { GetMroOrganizationFeesDocument, GetMroRtsTextsDocument } from "graphql/generated";
import { useSession } from "contexts";
import Button from "components/Button/Button";
import ViewFee from "./View";
import NewFee from "./New";

export default function FeesList({ updateFlyout, closeFlyout } : { updateFlyout: (props: { title: string, content: JSX.Element }) => void, closeFlyout: () => void }) {
    const { user } = useSession();
    const { data: { mroOrganizationFees } = {} } = useQuery(GetMroOrganizationFeesDocument, { variables: { mroOrganizationId: user?.mroOrganizationId } });
    const columns = useMemo(
        () => [
            {
                Header: 'Title',
                accessor: 'name',
                className: 'w-2/6',
            },
            {
                Header: 'Active',
                accessor: 'active',
                className: 'w-1/6',
                Cell: ({ value }) => value ? 'Yes' : 'No',
            },
            {
                Header: 'Value',
                accessor: 'value',
                className: 'w-1/6',
                Cell: ({ value }) => value,
            },
            {
                Header: 'Created',
                accessor: 'createdAt',
                className: 'w-3/6',
                Cell: ({ value }) => formatApiDate(value),
            },
        ],
        []
    );
    return (
        <Card title="Organization Fees">
            <div className="flex items-center gap-2 border border-slate-300 rounded">
                <Table columns={columns}
                onRowClick={(row) =>{ 
                    updateFlyout({ title: 'Edit Fee', content: <ViewFee closeFlyout={closeFlyout} id={row.id} /> })}}
                data={mroOrganizationFees ?? []}/>
            </div>
            <div className="flex justify-end mt-3">
                <Button text="Create New Fee" color="navy" size="xs" onClick={() =>{
                    updateFlyout({ title: 'New Fee', content: <NewFee closeFlyout={closeFlyout} /> });
                }} />
            </div>
        </Card>
    );
}