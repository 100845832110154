import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { SessionProvider } from 'contexts';
import Part145Layout from './views/layouts/Part145/Part145Layout';
import AuthLayout from './views/layouts/AuthLayout';
import Part91Layout from './views/layouts/Part91/Part91Layout';
import './assets/styles/App.css';
//Misc
import SignIn from 'views/Auth/SignIns';
import SignUp from 'views/Auth/SignUp';
import ResetPassword from 'views/Auth/ResetPassword';
// Part 145
import Dashboard from 'views/Part145/Dashboards';
import Customers from 'views/Part145/Customers';
import Customer from 'views/Part145/Customers/Customer';
import NewCustomer from 'views/Part145/Customers/New';
import Employees from 'views/Part145/Employees';
import Employee from 'views/Part145/Employees/Employee';
import Settings from 'views/Part145/Settings';
import WorkOrders from 'views/Part145/WorkOrders';
import WorkOrder from 'views/Part145/WorkOrders/Order';
import EditWorkOrder from 'views/Part145/WorkOrders/Order/Edit';
import NewWorkOrder from 'views/Part145/WorkOrders/New/index';
import Parts from 'views/Part145/Parts';
import ServiceRequests from 'views/Part145/ServiceRequests';
import MroCrafts from 'views/Part145/Crafts';
import MroCraft from 'views/Part145/Crafts/Craft';
import ServiceRequest from 'views/Part145/ServiceRequests/Request';
import ViewInvoice from 'views/Part145/CustomerServiceRequest/Invoice';
import Part from 'views/Part145/Parts/Part';
import EditPart from 'views/Part145/Parts/Part/Edit';
import Auth145Layout from 'views/Part145/Auth/Auth145Layout';
import { Can } from 'contexts/AbilityContext';
// Customer Service Requests
import CustomerServiceRequest from 'views/Part145/CustomerServiceRequest';
import ViewServiceRequest from 'views/Part145/CustomerServiceRequest/View';
// Part 91
import Crafts from './views/Part91/Crafts';
import Aircraft from './views/Part91/Aircraft';
import Status from './views/Part91/Aircraft/Status';
import Logbooks from './views/Part91/Aircraft/Logbooks';
import Documents from './views/Part91/Aircraft/Documents';
import Flights from './views/Part91/Flights';
import Logbook from 'views/Part91/Aircraft/Logbooks/Logbook/';
import Squawks from './views/Part91/Aircraft/Squawks';
import MaintenanceRecords from './views/Part91/Aircraft/MaintenanceRecords';
import Reservations from 'views/Part91/Reservations';
import Reports from 'views/Part91/Reports';
import Payments from 'views/Part91/Payments';
import Settings91 from 'views/Part91/Settings';
import Requests from 'views/Part91/Aircraft/Requests';

import * as Sentry from "@sentry/react";
import Redirect from 'components/Redirect/Redirect';
import Invitation from 'views/Auth/Invitation';
import Migration from 'views/Auth/Migration';
import StripeCallback from 'views/Part91/Redirects/StripeCallback';
import { ContatinsPermission } from 'utils/PermissionHandler';
import ForeFlightCallback from 'views/Part91/Redirects/ForeFlightCallback';

import PageError from 'views/PageError';
import Welcome from 'views/Auth/Welcome';
import Announcements from 'views/Part91/Announcements';
import Announcement from 'views/Part91/Announcements/View/index';
import OrganizationPicker from 'views/Part91/Redirects/OrganizationPicker';
import MroReports from 'views/Part145/Reports';


Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
});
const App = () => {
  return (
    <SessionProvider>
      <Router>
        <div className="App">
          {/* prettier-ignore */}
          <Routes>
            <Route path="*" element={<PageError />}>
            </Route>
            <Route path="" element={<Navigate to='/auth'></Navigate>}></Route>
            <Route path="/admin" element={ <Redirect url={process.env.REACT_APP_API_URL+'/admin'} /> } />
            <Route path="/stripe/connect" element={ <StripeCallback /> } />
            <Route path="/foreflight/connect" element={ <ForeFlightCallback /> } />
            
            <Route path="/welcome" element={
              <Welcome/> }>
            </Route>
            <Route path="/invitation/:id?/:token?" element={
              <Invitation/> }>
            </Route>
            <Route path="/sign-up/:coupon?" element={
              <SignUp/> }>
            </Route>
            <Route path="/entities/new" element={
              <SignUp/> }>
            </Route>
            <Route path="/auth" element={
            <AuthLayout/>}>
              <Route index path=':loginType?/:token?' element={<SignIn />}/>
              <Route path="forgot-password/:type?" element={<ResetPassword />}/>
              <Route path="forgot-password/:id/:token?" element={<ResetPassword />}/>
              <Route path="migrate/:id/:token/:loginType?" element={<Migration />}/>
            </Route>
            {/* Part 145 Routes */}
            <Route path="/mro" element={<AuthLayout />}>
              <Route path="auth">
                <Route index path=':loginType?/:token?' element={<Auth145Layout><SignIn /></Auth145Layout>}/>
                <Route path="forgot-password" element={<ResetPassword />}/>
                <Route path="forgot-password/:id/:token?" element={<ResetPassword />}/>
              </Route>
              <Route path="service-request">
                <Route index element={<CustomerServiceRequest />} />
                <Route path="invoice/:invoiceId/:token?" element={<ViewInvoice />} />
                <Route path="preview/:requestId" element={<ViewServiceRequest />} />
                <Route path=":requestId/:token" element={<ViewServiceRequest />} />
              </Route>
            </Route>
            <Route path="/mro" element={<Part145Layout />}>
              <Route path="" element={<Navigate to='/mro/dashboard'></Navigate>}></Route>
              <Route path="account">
                <Route path="settings" element={<Settings/>}></Route>
              </Route>
              <Route path="customers">
                <Route index element={<Can I="Manage" a="Customers" ><Customers /> </Can>}/>
                <Route path="new" element={<Can I="Manage" a="Customers" ><NewCustomer /></Can>}></Route>
                <Route path=":customerId" element={<Can I="Manage" a="Customers" ><Customer /></Can>}/>
              </Route>
              <Route path="dashboard">
                <Route index element={<Dashboard />}></Route>
              </Route>
              <Route path="mro-reports">
                <Route index element={<MroReports />}></Route>
              </Route>
              <Route path="employees">
                <Route index element={<Can I="Manage" a="Employees" > <Employees /> </Can>}/>
                <Route path=":employeeId" element={<Can I="Manage" a="Employees" ><Employee /></Can>}/>
              </Route>
              <Route path="parts">
                <Route index element={<Can I="Manage" a="Parts" > <Parts/> </Can>}/>
                <Route path=":partId">
                  <Route index element={<Can I="Manage" a="Parts"> <Part/> </Can>}/>
                  <Route path="edit" element={<Can I="Manage" a="Parts"> <EditPart/> </Can>}/>
                </Route>
              </Route>
              <Route path="crafts">
                <Route index element={<Can I="Manage" a="Crafts"> <MroCrafts/> </Can>}/>
                <Route path=":craftId" element={<Can I="Manage" a="Crafts"> <MroCraft/> </Can>}/>
              </Route>
              <Route path="work-orders">
                <Route index element={<Can I="Manage" a="Work Orders"> <WorkOrders /> </Can>}/>
                <Route path=":workOrderId">
                  <Route index element={<Can I="Manage" a="Work Orders"> <WorkOrder /> </Can>}/>
                  <Route path="edit" element={<Can I="Manage" a="Work Orders"> <EditWorkOrder /> </Can>}/>
                </Route>
                <Route path="new">
                  <Route index element={<Can I="Create" a="Work Orders"><NewWorkOrder /></Can> }/>
                  <Route path=":serviceRequestId" element={<Can I="Create" a="Work Orders"><NewWorkOrder /></Can>}/>
                </Route>
              </Route>
              <Route path="mro-service-requests">
                <Route index element={<ServiceRequests />}/>
                <Route path=":serviceRequestId" element={<ServiceRequest />}/>
              </Route>
            </Route>
            {/* Part 91 Routes */}
            <Route path="/org-picker" element={<OrganizationPicker />}></Route>
            <Route path="/crafts" element={<Part91Layout><Crafts /></Part91Layout>}></Route>
            <Route path="/aircraft" element={<Part91Layout><Aircraft /></Part91Layout>}></Route>
            <Route path="/aircraft/status" element={<Part91Layout><Status /></Part91Layout>}></Route>
            <Route path="/aircraft/logbooks">
              <Route index element={<ContatinsPermission permission={"Logbooks"} ><Part91Layout><Logbooks /></Part91Layout></ContatinsPermission>} />
              <Route path=":logbookId" element={<ContatinsPermission permission={"Logbooks"} ><Part91Layout><Logbook /></Part91Layout></ContatinsPermission>} />
            </Route>
            <Route path="/aircraft/squawks" element={<ContatinsPermission permission={"Squawks"} ><Part91Layout><Squawks /></Part91Layout></ContatinsPermission>}></Route>
            <Route path="/aircraft/documents" element={<ContatinsPermission permission={"Aircraft Documents"} ><Part91Layout><Documents /></Part91Layout></ContatinsPermission>}></Route>
            <Route path="/aircraft/requests" element={<ContatinsPermission permission={"Requests"} ><Part91Layout><Requests /></Part91Layout></ContatinsPermission>}></Route>            
            <Route path="/aircraft/maintenance-records" element={<Part91Layout><MaintenanceRecords /></Part91Layout>}></Route>

            <Route path="/reservations" element={<ContatinsPermission permission={"Reservations"} ><Part91Layout><Reservations /></Part91Layout></ContatinsPermission>} />
            <Route path="/flights" element={<ContatinsPermission permission={"Flights"} ><Part91Layout><Flights /></Part91Layout></ContatinsPermission>} />
            <Route path="/reports" element={<ContatinsPermission permission={"Reports"} ><Part91Layout><Reports /></Part91Layout></ContatinsPermission>} />
            <Route path="/payments" element={<ContatinsPermission permission={"Payments"} ><Part91Layout><Payments /></Part91Layout></ContatinsPermission>} />
            <Route path="/announcements" element={<ContatinsPermission permission={"Announcements"} ><Part91Layout><Announcements /></Part91Layout></ContatinsPermission>} />
            <Route path="/announcements/:id" element={<ContatinsPermission permission={"Announcements"} ><Part91Layout><Announcement /></Part91Layout></ContatinsPermission>} />
            <Route path="/account/settings" element={<Part91Layout><Settings91/></Part91Layout>} />

          </Routes>
        </div>
      </Router>
    </SessionProvider>
  );
};

export default App;
