import Button from "components/Button/Button";
import { useContext } from "react";
import { WalkthroughContext } from "..";

export default function SelectAircraft() {
    const { markAsComplete } = useContext(WalkthroughContext);
    return (
        <>
            <div className="absolute inset-0 bottom-0 bg-black/50 h-full z-[100] flex items-end justify-center pb-36" onClick={(e) => e.stopPropagation()}>
                <div className="bg-white min-h-44 min-w-48 p-4 rounded flex flex-col justify-around text-center">
                    <span className='text-lg font-bold'>^ Select Aircraft</span>
                    <span className=''>Select your aircraft to get started</span>
                    
                    <div className="flex gap-4 justify-center mt-4">
                        <Button text="Close Setup" color="red" size="xs" onClick={() => markAsComplete()} />
                    </div>
                </div>
            </div>
        </>
    )
}