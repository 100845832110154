import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikCheckBox, FullFormikInput } from "components/Form/StandardForm";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { CreateIntervalDocument, GetIntervalDocument, GetIntervalsDocument } from "graphql/generated";


export default function New({ closeFlyout }: { closeFlyout: () => void }) {
    const { craftId } = useSession();
    const [ createInterval ] = useMutation( CreateIntervalDocument, { onCompleted: closeFlyout, refetchQueries: [
        { query: GetIntervalDocument, variables: { craftId }},
        { query: GetIntervalsDocument, variables: { craftId }}
    ] } );
    
    return (
      <>
        <Formik enableReinitialize initialValues={{ name: "", optional: false }} onSubmit={(values) => createInterval({
            variables: { input: {
                craft: { connect: { id: craftId } },
                name: values.name,
                optional: values.optional,
                isCustom: true,
            } }
        })}>
            <Form className="p-4">
                <Card title="">
                    <div className="flex flex-col space-y-4">
                        <FullFormikInput name="name" label="Name" />
                    </div>
                    <div className="flex flex-col space-y-4">
                        <FullFormikCheckBox name="optional" isVertical label="Is Optional" />
                    </div>
                </Card>
                <div className="flex pt-4 gap-2 justify-end">
                    <Button text="Save" color="navy" size="xs" type="submit" />
                </div>
            </Form>
        </Formik>
      </>
    );
}