import { AbilityTuple, MongoAbility, MongoQuery } from "@casl/ability"
import { useSession } from "contexts";
import { AbilityContext } from "contexts/AbilityContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { OrgTypes } from "./orgTypes";

export const permissionHandler = (permission: string, ability: MongoAbility<AbilityTuple, MongoQuery>, orgType? :String) => {
    let userRolesRequired: string[] = [];
    
    switch (permission) {
        case 'Manage Inspections':
            userRolesRequired = ["Admin", 'Owner', "A & P"];
            break;
        case 'Squawks':
            userRolesRequired = ["Admin", 'Owner', "A & P", 'Pilot', "Student", "Sales Broker", "Insurrance Broker"];
            break;
        case 'Manage Squawks':
            userRolesRequired = ["Admin", 'Owner', "A & P", 'Pilot'];
            break;
        case 'Logbooks':
            userRolesRequired = ["Admin", 'Owner', "A & P", "Pilot", "Student", "Sales Broker", "Insurrance Broker"];
            break;
        case 'Aircraft Documents':
            userRolesRequired = ["Admin", 'Owner', "A & P", "Pilot", "Student", "Sales Broker", "Insurrance Broker"];
            break;
        case 'Manage Aircraft Documents':
            userRolesRequired = ["Admin", 'Owner', "Pilot", "A & P"];
            break;
        case 'Organization Documents':
            userRolesRequired = ["Admin", 'Owner', "Pilot", "Student"];
            break;
        case 'Mangage Organization Documents':
            userRolesRequired = ["Admin", 'Owner', "Pilot"];
            break;
        case 'Requests':
            userRolesRequired = ['Admin'];
            break;
        case 'Flights':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Delete Flight':
            userRolesRequired = ['Admin', 'Owner', "Pilot"];
            break;
        case 'Reservations':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Reports':
            userRolesRequired = ["Admin", 'Owner', "Pilot", "A & P", "Student"];
            break;
        case 'Payments':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Announcements':
            userRolesRequired = ['Admin', 'Owner', "Pilot", "Student"];
            break;
        case 'Create Announcement':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        case 'Payment Admin':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        case 'Add Users':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        case 'Manage Intervals':
            userRolesRequired = ['Admin', 'Owner'];
            break;
        default:
            break;
    }

    let can = false;
    for (const subject of userRolesRequired) {
        if(ability.can('PART91', subject)) {
            can = true;
            break;
        }
    }
    

    if(orgType?.includes("SOLO")){
        switch (permission) {
            case 'Reservations':
                can = false;
                break;
            case 'Reports':
                can = false;
                break;
            case 'Payments':
                can = false;
                break;
            case 'Payments':
                can = false;
                break;
        }
    }
    return can;
}

export const ContatinsPermission = ({ permission, children }) => {
    const abilities = useContext(AbilityContext);
    const { organizationType } = useSession();
    let can = permissionHandler(permission, abilities);
    const navigate = useNavigate();

    if(!can && organizationType === OrgTypes.PART145) navigate('/mro/dashboard');
    else if(!can) navigate('/crafts');

    return (
        <>
            {can ? 
            <>
                {children} 
            </> : null}
        </>
    );
}

export const doesContatinSubjects = ({ action, subjects, ability }) => {
    let can = false;
    for (const subject of subjects) {
        if(ability.can(action, subject)) {
            can = true;
            break;
        }
    }
    return can;
  }