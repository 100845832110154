import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikInput, FullFormikSelect, FullFormikTextarea } from "components/Form/StandardForm";
import { useSession } from "contexts";
import { Form, Formik } from "formik";
import { CreateTransactionDocument, GetOrganizationBillingInfoDocument, GetOrganizationWalletsDocument, GetTransactionsDocument, GetUserOrganizationProfilesDocument, GetUserWalletDocument, StripePayBalanceDocument, TransactionStatus } from "graphql/generated";
import { useEffect, useState } from "react";

export default function NewTransaction({ closeFlyout, ...props }: { closeFlyout: () => void} ) {
    const { organizationId, user } = useSession();
    const { data : { wallets } = { wallets: [] } } = useQuery(GetOrganizationWalletsDocument, { variables: { organizationId }  });
    const [ initialValues, setInitialValues ] = useState({ accountHolder: '', tranactionType: 'DEPOSIT', amount: '', description: '' });
    const [ createTransaction ] = useMutation(CreateTransactionDocument, { onCompleted: closeFlyout, refetchQueries: [
        GetTransactionsDocument,
        { query: GetOrganizationBillingInfoDocument, variables: { organizationId } },
        { query: GetTransactionsDocument, variables: { where: { wallet: { is: { userProfileId: { equals: user?.userOrganizationProfileId } } } } } },
        { query: GetUserWalletDocument, variables: { userProfileId: user.userOrganizationProfileId } },
        { query: StripePayBalanceDocument, variables: { id: user.userOrganizationProfileId } },
    ] });
    useEffect(() => {
        if (wallets?.length) {
            setInitialValues({ ...initialValues, accountHolder: wallets.filter((wallet) => wallet.userProfile.user)[0].id });
        }
    }, [wallets]);
    return (
        <Formik 
            initialValues={initialValues} enableReinitialize onSubmit={async (values: typeof initialValues) => { 
                await createTransaction({
                    variables: {
                        input: {
                            value: values.amount,
                            status: TransactionStatus.Success,
                            wallet: { connect: { id: values.accountHolder } },
                            timestamp: new Date(),
                            inputData: { description: values.description, function: 'MANUAL', quantity: 1, rate: 0 },
                            isDeposit: values.tranactionType === 'DEPOSIT',
                        }
                    }
                });
            } }>
            <Form className="flex flex-col px-3">
                <Card>
                    <div className="flex flex-col">
                        <FullFormikSelect name="accountHolder" label="Account Holder">
                            { wallets.filter((wallet) => wallet.userProfile.user)?.map(wallet => (
                                <option key={wallet.id} value={wallet.id}>{wallet?.userProfile?.user?.firstName} {wallet?.userProfile?.user?.lastName}</option>
                            )) }
                        </FullFormikSelect>
                    </div>
                    <div className="flex flex-col mt-3">
                        <FullFormikSelect name="tranactionType" label="Tranaction Type">
                            <option value="DEPOSIT">Decrease Account Balance</option>
                            <option value="WITHDRAW">Increase Account Balance</option>
                        </FullFormikSelect>
                    </div>
                    <div className="flex flex-col">
                        <FullFormikInput name="amount" label="Amount" />
                    </div>
                    <div className="flex flex-col">
                        <FullFormikTextarea name="description" id="description" label="Description" />
                    </div>
                </Card>
                <div className="flex justify-end mt-3 gap-5">
                    <Button text="Cancel" color="pale" onClick={closeFlyout} size="xs" />
                    <Button text="Create Transaction" color="navy" type='submit' size="xs" />
                </div>
            </Form>
        </Formik>
    )
};