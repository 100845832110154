import { useState, useMemo } from 'react';
import { Table } from 'components/Table/Table';
import { useQuery } from '@apollo/client';
import { GetMroPartInventoriesDocument, GetMroPartVariantsDocument } from 'graphql/generated';
import { FlyoutHookReturn } from 'components/Flyout/Flyout';
import { useSession } from 'contexts';
import Tabs from 'components/Tabs/Tabs';
import { webFormat } from 'utils/statuses';
import ViewVariant from './View';
import Button from 'components/Button/Button';
import LinkNewVariant from './New';
import { StarIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const Variants = function ({
  updateFlyout,
  closeFlyout,
  partId,
}: {
  updateFlyout: FlyoutHookReturn['updateFlyout'];
  closeFlyout: FlyoutHookReturn['closeFlyout'];
  partId?: string;
}) {
    const [activeTab, setActiveTab] = useState('All');
    const tabs = ['All', 'Left Variants', 'Right Variants'];
    const { data: { mroPart } = {} } = useQuery(GetMroPartVariantsDocument, {
        variables: { id: partId },
    });
    const navigate = useNavigate();
    const tableData = useMemo(() => {
        const leftVariants = mroPart?.variantsLeft?.map(item => ({ ...item, variant: "Left" })) || [];
        const rightVariants = mroPart?.variantsRight?.map(item => ({ ...item, variant: "Right" })) || [];
    
        // Merge logic for "both"
        const mergedVariants = [...leftVariants];
        rightVariants.forEach(item => {
        const matchIndex = leftVariants.findIndex(leftItem => leftItem.id === item.id); // Adjust key comparison if necessary
        if (matchIndex !== -1) {
            // If present in both, mark as "both" and replace in mergedVariants
            mergedVariants[matchIndex] = { ...leftVariants[matchIndex], variant: "Both" };
        } else {
            // Add the right variant if not already present
            mergedVariants.push(item);
        }
        });
    
        switch (activeTab) {
        case "All":
            return mergedVariants;
        case "Left Variants":
            return mergedVariants.filter(item => item.variant === "Left" || item.variant === "Both");
        case "Right Variants":
            return mergedVariants.filter(item => item.variant === "Right" || item.variant === "Both");
        default:
            return [];
        }
    }, [mroPart, activeTab]);

    const columns = useMemo(
        () => [
            {
                Header: 'Part #',
                accessor: 'partNumber', // accessor is the "key" in the data
                className: 'w-1/6',
                Cell: ({ value, row }: any) => {
                    console.log(row);
                    return <div className='bg-slate-100 flex flex-row items-center rounded px-2 py-1 gap-1'>
                        <span className="font-bold text-brand-dark underline" onClick={(e) =>{
                            navigate(`/mro/parts/${row.original.id}`);
                            e.stopPropagation();
                        }}>{value}</span>
                        {row.original.preferred && <StarIcon className="h-4 w-4 mt-[-2px] flex-shrink-0" />}
                    </div>;
                },
            },
            {
                Header: 'Description',
                accessor: 'description',
                className: 'w-2/6',
                Cell: ({ value, row }: any) => {
                return <span className="font-medium">{value}</span>;
                },
            },
            {
                Header: 'Variant',
                accessor: 'variant',
                className: 'w-2/6',
                Cell: ({ value, row }: any) => {
                    return <span className="">{webFormat(value)}</span>;
                },
            },
        ],
        [partId]
    );
    return (
        <>
            <div className="flex flex-col justify-between items-start border-y border-slate-200 pt-1 bg-slate-50 -mt-2">
                <div className="flex justify-between w-full">
                    <Tabs setActiveItem={setActiveTab} activeItem={activeTab} items={tabs} />
                </div>
            </div>
            <div className={`bg-white border-slate-200 ${partId && 'border'}`}>
                <Table
                columns={columns}
                data={tableData ?? []}
                onRowClick={(obj) =>{
                    updateFlyout({
                        title: 'View Variant',
                        content: <ViewVariant partId={obj.id} connectedPartId={partId} variantLocation={obj.variant} closeFlyout={closeFlyout} />,
                    })
                }}
                />
            </div>
        </>
    );
};

export default Variants;
