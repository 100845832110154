import { useEffect, useState } from 'react';
import { useSession } from 'contexts/SessionContext';
import { useMutation, useQuery } from '@apollo/client';
import { GetLogbooksDocument, GetLogbookDocument, GetLogEntryDocument, UpdateLogEntryDocument, GetUserInfoDocument, DeleteLogEntryDocument } from 'graphql/generated';
import Card from 'components/Card/Card';
import Button from 'components/Button/Button';
import { ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/24/solid';
import Edit from '../Edit';
import { webFormat } from 'utils/statuses';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import { formatApiDate } from 'utils/formatter';
import isMobileApp from 'utils/isMobileApp';
import DocumentViewer from 'components/DocumentViewer/DocumentViewer';
import Modal from 'components/Modal/Modal';
import SignOffModal from '../../Components/SignOffModal';
const View = function ({ id, closeFlyout: closeView } : { id: string, closeFlyout: () => void}) {
  const { data: { me } = { } } = useQuery(GetUserInfoDocument);
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [showDocument, setShowDocument ] = useState(null);
  const { data : { logEntry } = { }, refetch } = useQuery(GetLogEntryDocument, { variables: { logEntryId: id } });
  
  const [ showSign, setShowSign ] = useState(false);
  const [ showDelete, setShowDelete ] = useState(false);
  const [deleteLogEntry] = useMutation(DeleteLogEntryDocument, {
    refetchQueries: [{ query: GetLogbookDocument, variables: { logBookId:logEntry?.logBook?.id } }],
    onCompleted: () => closeView(),
  });
  
  return (
    <>
      {showDocument && <DocumentViewer uri={showDocument?.uri} fileType={showDocument?.fileType} close={()=>setShowDocument(null)} />}
      {showDelete && <Modal message={"You are about to delete this Log Entry."} accept={() => deleteLogEntry({ variables:{ id } })} cancel={setShowDelete} />}
      {showSign && <SignOffModal setShowSign={setShowSign} id={id} logEntry={logEntry} />}
      <Flyout {...flyoutProps} />
      <div className="px-4">
        <Card>
          <div className="p-4 flex flex-col w-full gap-2">
            <div className='flex justify-between'>
              <h1 className="font-bold text-lg truncate md:text-xl">{logEntry?.title}</h1>
              { !logEntry?.signedOffAt && <PencilIcon className='h-5 w-5 flex-shrink-0 cursor-pointer' onClick={()=> updateFlyout({ title: 'Edit Log Entry', content: <Edit id={id} closeFlyout={(didDelete)=>{
                refetch();
                closeFlyout();
                if(didDelete){
                  closeView();
                }
              }}/> })} />} 
            </div>
            <h2 className=''><span className='font-semibold'>Description:</span> <div className='overflow-x-auto' dangerouslySetInnerHTML={ { __html: logEntry?.description  } } /></h2>
            { logEntry?.systemsAffected?.length > 0 && <h3 className='text-sm mb-1'><span className='font-semibold'>JASC:</span> {logEntry?.systemsAffected.map((system, index) => 
              `${system.code}-${webFormat(system.name)}`
            ).join(', ')}</h3> }
            <h3 className='text-sm'><span className='font-semibold'>Date Performed:</span> {formatApiDate(logEntry?.logDate)}</h3>
            {logEntry?.signedOffAt && <h3 className='text-sm'><span className='font-semibold'>Date Signed Off:</span> {formatApiDate(logEntry?.signedOffAt)}</h3>}
            {logEntry?.signedOffBy && <h3 className='text-sm'><span className='font-semibold'>Signed By:</span> {logEntry?.signedOffBy}</h3>}
            {logEntry?.signOffInfo && <h3 className='text-sm text-wrap'><span className='font-semibold'>Sign Info:</span> {logEntry?.signOffInfo}</h3>}
          </div>
        </Card>
        {logEntry?.intervalValues && Object.keys(logEntry?.intervalValues)?.length > 0 && <Card title='Intervals'>
          <div className='flex flex-wrap gap-4 ml-3'>
            { Object.keys(logEntry.intervalValues).map((key, index) => (
              <div className='flex flex-col gap-2 mr-4 text-center' key={index}>
                <span className='text-sm'>{key}</span>
                <span className='text-sm font-bold'>{logEntry.intervalValues[key]}</span>
              </div>
            ))}
          </div>
        </Card> }
        {logEntry?.attachments?.length > 0 && <Card className='w-full' title="Attachments">
            <div className='flex flex-wrap gap-4 ml-3'>
              {logEntry?.attachments?.map((file, index) => (
                <div className='flex flex-col gap-2' key={index}>
                  {isMobileApp() ? 
                    <span className='text-blue-500 underline' onClick={() => setShowDocument({uri: file?.signedUrl, fileType: file?.fileType })}>{file.name}</span>
                  :
                    <a href={file.signedUrl} target="_blank" className='text-blue-500 underline'>{file.name}</a>
                  }
                </div>
              ))}
            </div>
        </Card> }
        <div className="pt-3">
          <div className="flex justify-end gap-4">
            <Button
              text="Delete"
              color="red"
              onClick={() => setShowDelete(true)}
              size="xs" /> 
            { !logEntry?.signedOffAt && <Button
              text="Sign Log Entry"
              color="navy"
              onClick={() => setShowSign(true)}
              size="xs" /> }
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
