import { ChevronRightIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import UserIcon from 'components/UserIcon/UserIcon';

import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroEmployeeDocument, GetMroEmployeesDocument, RemoveMroEmployeeDocument } from 'graphql/generated';
import { isActive, isUpcoming, webFormat } from 'utils/statuses';
import useSaveState from 'hooks/useSaveState';
import TabCards, { TabCardItem } from 'components/TabCards/TabCards';
import EmployeeOverview from './Partial/Overview';
import { EmployeeSettings } from './Partial/Settings';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react/jsx-runtime';
import { useState } from 'react';
import Modal from 'components/Modal/Modal';
import { useSession } from 'contexts';


const Employee = function () {
  const { employeeId } = useParams();
  const { user } = useSession();
  const navigate = useNavigate();
  const { data: { mroEmployeeProfile } = {} } = useQuery(GetMroEmployeeDocument, {
    variables: { mroEmployeeId: employeeId },
  });
  const [deleteEmployee] = useMutation(RemoveMroEmployeeDocument, {
    refetchQueries: [{ query: GetMroEmployeesDocument, variables: { mroOrganizationId: user?.mroOrganizationId } }],
    onCompleted: () => navigate('/mro/employees'),
  });
  const [showModal, setShowModal] = useState(false);
  const tabsStyle = 'p-6 bg-white border border-slate-300 max-w-[calc(78rem-2px)] w-full rounded-b rounded-r shadow-blue';
  const tabs : TabCardItem[] = [
    {
      title: 'Overview',
      content: (
        <div className={tabsStyle}>
          <EmployeeOverview employeeId={employeeId} />
        </div>
      ),
    },
    {
      title: 'Settings',
      content: (
        <div className={tabsStyle}>
          <EmployeeSettings employeeId={employeeId} />
        </div>
      ),
    },
  ];
  const [currentTab, setCurrentTab] = useSaveState('employeeCardTab', tabs[0].title);

  const workOrders = mroEmployeeProfile?.mroWorkOrders;
  const activeOrders = workOrders?.filter((order) => isActive(order.status));
  const upcomingOrders = workOrders?.filter((order) => isUpcoming(order.status));
  return (
    <div className='pb-20 md:pb-0'>
      {showModal && <Modal  message={"You are about to delete this employee"} cancel={setShowModal} accept={() => deleteEmployee( { variables:{
        id: employeeId
      } } )}/>}
      <div className="bg-white border rounded border-slate-300">
        <div className="flex rounded-t items-center justify-between p-6">
          <div className="flex items-center">
            <UserIcon
              iconSize="xl"
              iconText={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              iconId={mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              iconColor={mroEmployeeProfile?.profileColor}
              className=""
            />
            <div className="ml-2 flex flex-col">
              <h1 className="text-brand-dark text-left font-bold text-xl md:text-2xl">
                {mroEmployeeProfile?.firstName + ' ' + mroEmployeeProfile?.lastName}
              </h1>
              <div className="-mt-1 text-sm text-slate-500">
                <span className="text-brand-electric font-medium mr-2 pr-2 border-r border-slate-300">{mroEmployeeProfile?.title}</span>
                <span className="mr-2 pr-2 border-r border-slate-300">{mroEmployeeProfile?.phone}</span>
                <span>{mroEmployeeProfile?.email}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {(() =>{
              if(!mroEmployeeProfile?.accountSetup){
                return <div className="bg-slate-50 text-sm rounded px-6 py-1 text-slate-400 font-bold">Not Setup</div>
              }else if(mroEmployeeProfile?.mroLaborEntries && mroEmployeeProfile?.mroLaborEntries?.length > 0){
                return <div className="bg-brand-pale text-sm rounded px-6 py-1 text-brand-electric font-bold">Active</div>  
              }else{
                return <div className="bg-slate-50 text-sm rounded px-6 py-1 text-slate-400 font-bold">Inactive</div>
              }
            })()}
            <Menu
                as="div"
                className="flex items-center justify-center relative group transition ml-2 w-[28px] border border-white rounded hover:border-slate-200 hover:shadow-inner">
                <Menu.Button className="">
                  <EllipsisVerticalIcon className="h-5 w-5 text-slate-500 cursor-pointer" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="origin-top-right absolute top-full right-0 mt-2 rounded shadow-blue-lg border border-slate-300 bg-white focus:outline-none z-[99] w-36">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? 'bg-slate-100' : ''} block px-4 py-3 text-sm text-slate-700 w-full text-right rounded`}
                          onClick={() => setShowModal(true)}>
                          Delete
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
          </div>
        </div>
      </div>
      <div className="my-2">
        <TabCards items={tabs} 
            activeTitle={currentTab}
            setActiveTitle={setCurrentTab} 
            addBars={false} />
      </div>
    </div>
  );
};

export default Employee;
