import Button from "components/Button/Button";
import { useContext } from "react";
import { WalkthroughContext } from "..";

export default function CloseAnnualFlyout() {
    const { markAsComplete, changeStep } = useContext(WalkthroughContext);
    return (
        <>
            <div className="absolute inset-0 bottom-0 z-[100] flex justify-center items-end px-2">
                <div className="bg-black/50 h-[76%] w-full" onClick={(e) => e.stopPropagation()}>
                    <div className="bg-white min-h-44 min-w-36 p-4 rounded flex flex-col justify-around text-center">
                        <span className=''>Click on the X in the top right to close out of this inspection</span>
                        <div className="flex gap-4 justify-center mt-4">
                            <Button text="Close Setup" color="red" size="xs" onClick={() => markAsComplete()} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 bg-black/50 z-[50] flex items-start justify-center pt-10 px-2" onClick={(e) => e.stopPropagation()}>
               
            </div>
        </>
    )
}