import React, { useEffect, useState } from 'react';
import { Table } from 'components/Table/Table';
import { PlusIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { BriefcaseIcon } from '@heroicons/react/24/solid';
import { AirplaneIcon } from 'assets/icons';
import { useMutation, useQuery } from '@apollo/client';
import { GetMroDashboardDocument, GetMroDashboardOrdersDocument, GetMroWorkOrderCategoriesDocument, GetMroWorkOrderQuery, GetMroWorkOrdersDocument, UpdateMroWorkOrderDocument } from 'graphql/generated';
import { formatApiDate, formatOrderNumber, formatWithZeros } from 'utils/formatter';
import { Link, useNavigate } from 'react-router-dom';
import UserIcon from 'components/UserIcon/UserIcon';
import StatusButton from 'components/StatusButton/StatusButton';
import { orderCase } from 'utils/statuses';
import { useSession } from 'contexts';
import Flyout, { useFlyout } from 'components/Flyout/Flyout';
import EditAssignees from './Order/Edit/Components/EditAssignees';
import Tabs from 'components/Tabs/Tabs';
import useSaveState from 'hooks/useSaveState';
import { Can } from 'contexts/AbilityContext';
import WorkOrderNumber from 'components/WorkOrderNumber/WorkOrderNumber';

const WorkOrders = function (props) {
  const [activeTab, setActiveTab] = useSaveState('workOrderActiveTab', 'Open');
  const { flyoutProps, updateFlyout, closeFlyout } = useFlyout();
  const [query, setQuery] = useState<string>();
  const [selectedWorkOrderCat, setSelectedWorkOrderCat] = useSaveState("workOrderCat",'all');
  const { user } = useSession();
  const { data : { mroWorkOrderCategories } =  {} } = useQuery(GetMroWorkOrderCategoriesDocument, { variables: { where: { mroOrganizationId: { equals: user?.mroOrganizationId } } } } );
  const { data: { mroWorkOrders } = {}, refetch } = useQuery(GetMroWorkOrdersDocument, {
    variables: {
      where: {
        mroOrganizationId: { equals: user?.mroOrganizationId },
        ...( selectedWorkOrderCat !== 'all' && { mroWorkOrderCategoryId: { equals: (selectedWorkOrderCat !== 'na' ? selectedWorkOrderCat : null) } })
      },
    },
  });
  const [updateWorkOrder] = useMutation(UpdateMroWorkOrderDocument, { refetchQueries: [GetMroDashboardDocument, GetMroDashboardOrdersDocument ] });

  const navigate = useNavigate();

  const showEditAssignees = (e, assignees, workOrderId) => {
    e.stopPropagation();

    const workOrder = mroWorkOrders.find((order) => order.id === workOrderId);
    updateFlyout({
      title: 'Update Assignees',
      content: (
        <EditAssignees
          selectedAssignees={assignees}
          setSelectedAssignees={(newAssignees) => {
            const notAssigneed = [];
            workOrder?.allAssignees.forEach((assignee) => {
              if (!newAssignees?.find((newAssignee) => assignee.id === newAssignee.id)) {
                notAssigneed.push({ id: assignee.id });
              }
            });
            updateWorkOrder({
              refetchQueries: [GetMroWorkOrdersDocument],
              variables: {
                input: {
                  id: workOrder.id,
                  assignees: {
                    connect: newAssignees.map((assignee) => {
                      return { id: assignee.id };
                    }),
                    disconnect: notAssigneed,
                  },
                },
              },
            });
          }}
          closeFlyout={closeFlyout}
        />
      ),
    });
  };
  // TABLE COLUMNS
  const columns = React.useMemo(
    () => [
      {
        Header: 'Order # / Cat',
        accessor: (obj) => {
          return {
            workOrderNum: obj.workOrderNum,
            mroWorkOrderCategory: obj.mroWorkOrderCategory,
          };
        },
        className: 'w-full max-w-[10rem]',
        Cell: ({ value }) => {
          const color = value.mroWorkOrderCategory?.color ? "text-[" + value.mroWorkOrderCategory?.color + "]" : 'text-slate-500';
          return (
            <div className='flex flex-col justify-center'>
              <WorkOrderNumber code={value.mroWorkOrderCategory?.code} workOrderNum={value.workOrderNum} />
              <span className={`text-xs p-0.5 ${ color } font-semibold text-center`}>{value.mroWorkOrderCategory?.name}</span>
            </div>
          );
        },
      },
      {
        Header: 'Aircraft',
        accessor: (obj) => [obj?.mroCraft?.tailNumber, `${obj?.mroCraft?.make} ${obj?.mroCraft?.model}`, obj?.status],
        className: 'w-64 shrink-0',
        Cell: ({ value, row }: any) => {
          if (Array.isArray(value)) {
            return (
              <div className="flex items-center gap-2">
                <div
                  className={`${orderCase[value[2]]?.textColor} ${
                    orderCase[value[2]]?.bgColor
                  } rounded h-8 w-8 flex items-center justify-center`}>
                  <AirplaneIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-col">
                  <span className="text-lg font-bold text-brand-dark">{value[0]}</span>
                  <span className="-mt-2">{value[1]}</span>
                </div>
              </div>
            );
          } else {
            return value;
          }
        },
      },
      {
        Header: 'Description',
        accessor: 'title',
        className: 'w-full',
      },
      {
        Header: 'Assignee(s)',
        accessor: 'assignees',
        className: 'w-full justify-center max-w-[8rem]',
        Cell: ({ value, row }: any) => {
          if (!Array.isArray(value)) {
            value = [value];
          }
          return (
            <button
              onClick={(e) => showEditAssignees(e, value, row.original.id)}
              className="flex flex-wrap -ml-[0.125rem] -my-[0.125rem]">
              {value.length === 0 ? (
                <div className="flex items-center justify-center h-7 w-7 bg-brand-pale text-brand-electric rounded-full border border-dashed border-brand-electric cursor-pointer transition-all relative mb-0.5 hover:text-white hover:bg-brand-electric hover:border-solid">
                  <PlusIcon className="h-4 w-4" />
                </div>
              ) : (
                value.map((item, index) => {
                  return (
                    <UserIcon
                      key={index}
                      iconSize="sm"
                      iconText={item.firstName + ' ' + item.lastName}
                      iconId={item.firstName + ' ' + item.lastName}
                      iconColor={item.profileColor}
                      className="border-2 border-slate-50"
                    />
                  );
                })
              )}
            </button>
          );
        },
      },
      {
        Header: 'Promise Date',
        accessor: 'promiseDate',
        className: 'w-full justify-end max-w-[8rem]',
        Cell: ({ value }) => {
          return formatApiDate(value);
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'w-64 shrink-0',
        Cell: ({ value, row }: any) => {
          return <StatusButton readOnly={true} context="order" text={value} className="" onSelect={(e) => {}} />;
        },
      },
    ],
    [showEditAssignees]
  );

  // HANDLES SEARCH BAR/CATEGORY FUNCTIONALITY
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch(
        {
          where: { OR: [{ title: { search: query?.trim().replaceAll(' ', ' & ') } }, 
            { ...(isNaN(parseInt(query)) &&
              { mroCraft: { is: { tailNumber: { contains: query?.trim().replaceAll(' ', ' & ') } } } }) }, // Don't search for tail number if query is a number
            { workOrderNum: { equals: Number(query) } }],
            ...( selectedWorkOrderCat !== 'all' && { mroWorkOrderCategoryId: { equals: (selectedWorkOrderCat !== 'na' ? selectedWorkOrderCat : null) } })
          },
        }
      ).then(() => setActiveTab('Open'));
    }, 400);
    return () => clearTimeout(delayDebounceFn);
    
  }, [query, refetch]);
  // HANDLES FILTER TAB FUNCTIONALITY
  const tabs = ['Open', 'Scheduled', 'Draft', 'Closed', 'All'];
  const tableData = React.useMemo(() => {
    let statuses = [];
    switch (activeTab) {
      case 'All':
        return mroWorkOrders;
      case 'Open':
        statuses = ['in_progress', 'open', 'draft', 'scheduled', 'hold'];
        break;
      case 'Scheduled':
        statuses = ['scheduled'];
        break;
      case 'Closed':
        statuses = ['closed', 'completed', 'cancelled'];
        break;
      case 'Draft':
        statuses = ['draft'];
        break;
    }
    return mroWorkOrders?.filter((item) => statuses.includes(item.status.toLowerCase()));
  }, [mroWorkOrders, activeTab]);

  return (
    <div className='pb-20 md:pb-0'>
      <Flyout {...flyoutProps} />
      <div className="flex justify-between items-center bg-white p-6 border border-slate-300 rounded mb-2 shadow-blue">
        <div className="flex items-center">
          <BriefcaseIcon className="h-8 w-8 bg-brand-pale mr-2 text-brand-electric rounded p-[0.375rem]" />
          <h1 className="text-left font-bold text-brand-dark text-xl md:text-2xl">Work Orders</h1>
        </div>
        <div className="flex items-stretch gap-2 flex-wrap justify-end">
          <div className="relative flex items-center">
            <MagnifyingGlassIcon className="h-4 w-4 absolute left-2 text-slate-400" />
            <input
              value={query ?? ''}
              onChange={(e) => setQuery(e.target.value)}
              className="border border-slate-300 bg-slate-50 rounded h-8 placeholder:text-slate-400 px-2 pl-7"
              placeholder="Search"
            />
          </div>
          <Can I="Create" a="Work Orders">
            <Link
              to="/mro/work-orders/new"
              className="flex items-center bg-brand text-white border border-brand text-sm rounded font-semibold py-1.5 px-4">
              <PlusIcon className="h-3 w-3 mr-1" />
              Add New
            </Link>
          </Can>
        </div>
      </div>
      <div className="p-6 bg-white border rounded border-slate-300 w-full max-w-[calc(78rem-2px)] shadow-blue h-full mb-10">
        <div className="flex flex-wrap sm:flex-nowrap gap-3 md:gap-0 justify-between items-center border-y border-slate-200 pt-1 bg-slate-50 w-[calc(100%+3rem)] -ml-6 px-8 -mt-2">
          <Tabs items={tabs} setActiveItem={setActiveTab} activeItem={activeTab} />
          <div>
            <span className='font-semibold pr-2'>Categories:</span>
            <select value={selectedWorkOrderCat} onChange={(e) => setSelectedWorkOrderCat(e.target.value) } className="border border-slate-300 rounded mr-1 mt-[-3px] text-sm">
              <option value="all">All</option>
              <option value="na">N/A</option>
              {mroWorkOrderCategories?.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Table
          columns={columns}
          data={tableData || []}
          onRowClick={(workOrder: GetMroWorkOrderQuery['mroWorkOrder']) => navigate(workOrder.id)}
        />
      </div>
    </div>
  );
};

export default WorkOrders;