import Button from "components/Button/Button";
import { WalkthroughContext } from "..";
import { useContext } from "react";

export default function WelcomeToCoflyt() {
    const { markAsComplete, prevStep, changeStep } = useContext(WalkthroughContext);
    const nextStep = () => {
        changeStep('welcomeToCoflyt', 'selectAircraft', false);
    };
    return (
        <>
            <div className="absolute inset-0 bg-black/50 z-[100] h-full flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
                <div className="flex items-center justify-center">
                    <div className="bg-white min-h-48 min-w-48 p-4 rounded flex flex-col justify-around text-center py-5">
                        <span className='text-xl font-bold'>Welcome to Coflyt</span>
                        <span className='text-lg'>Please get your aircraft logbooks before getting started with Coflyt</span>
                        <div className="flex gap-4 justify-center mt-4 flex-wrap">
                            <Button text="Close Setup" color="red" size="sm" onClick={() => markAsComplete()} />
                            <Button text="Skip Step" color="pale" size="sm" onClick={nextStep} />
                            <Button text="I have it!" color="brand" size="sm" onClick={nextStep} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}