import { createContext, ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WelcomeToCoflyt from "./Steps/WelcomeToCoflyt";
import { useMutation, useQuery } from "@apollo/client";
import { useSession } from "contexts";
import { CompletedWalkthroughDocument, HasCompletedWalkthroughDocument } from "graphql/generated";
import SelectAircraft from "./Steps/SelectAircraft";
import StatusPageOverview from "./Steps/StatusPageOverview";
import SelectInspections from "./Steps/SelectInspections";
import InspectionPageOverview from "./Steps/InspectionPageOverview";
import SelectAnnualInspection from "./Steps/SelectAnnualInspection";
import SetupAnnualInspection from "./Steps/SetupAnnualInspection";
import CloseAnnualFlyout from "./Steps/CloseAnnualFlyout";

interface WalkthroughContextInterface {
    formStep: string;
    changeStep: any;
    prevStep: string[];
    resetState: () => void;
    markAsComplete: () => void;
}

export const WalkthroughContext = createContext<WalkthroughContextInterface | null>(null);
const getStep = (step: string) => {
    switch (step) {
        case 'selectAircraft':
            return <SelectAircraft />;
        case 'statusPageOverview':
            return <StatusPageOverview />;
        case 'selectInspections':
            return <SelectInspections />;
        case 'inspectionsPageOverview':
            return <InspectionPageOverview />;
        case 'selectAnnualInspection':
            return <SelectAnnualInspection />;
        case 'setupAnnualInspection':
            return <SetupAnnualInspection />;
        case 'closeAnnualFlyout':
            return <CloseAnnualFlyout />;
        default:
            return <WelcomeToCoflyt />;
    }
};
export default function Walkthrough({ children }: { children: ReactNode }) {
    const { user } = useSession();
    const { data: { hasCompletedWalkthrough } = { hasCompletedWalkthrough: true } } = useQuery( HasCompletedWalkthroughDocument, { variables: { id: user?.id } });
    const [ completedWalkthrough ] = useMutation(CompletedWalkthroughDocument, { refetchQueries: [ { 
        query: HasCompletedWalkthroughDocument, variables: { id: user?.id }
    } ] });
    const [formStep, setFormStep] = useState('welcomeToCoflyt');
    const [prevStep, setPrevStep] = useState(['welcomeToCoflyt']);
    const navigate = useNavigate();
    const location = useLocation();
    const defPath = window.location.pathname; 
    const changeStep = function (prev, next, backwards) {
        if (next) {
          setFormStep(next);
          navigate(defPath + '?step=' + prevStep.length);
        }
        if (backwards) {
          setFormStep(prevStep[prevStep.length - 1]);
          let newArr = prevStep.slice(0, -1);
          setPrevStep(newArr);
        } else {
          setPrevStep([...prevStep, prev]);
        }
    };
    const resetState = () => {
        setFormStep('welcomeToCoflyt');
        setPrevStep(['welcomeToCoflyt']);
    };
    
    const markAsComplete = async() => {
        completedWalkthrough({ variables: { id: user?.id } });
        setPrevStep(['welcomeToCoflyt']);
    };
    useEffect(() => {
        if(!hasCompletedWalkthrough) {
            //Lock user to walkthrough
            if(formStep === 'welcomeToCoflyt' || formStep === 'selectAircraft') {
                location.pathname !== '/crafts' && navigate('/crafts');
            }else if(formStep === 'selectInspections' || formStep === 'statusPageOverview') {
                location.pathname !== '/aircraft' && navigate('/aircraft');
            }else if(formStep === 'inspectionsPageOverview' || formStep === 'selectAnnualInspection' || formStep === 'setupAnnualInspection' || formStep === 'closeAnnualFlyout') {
                location.pathname !== '/aircraft/status' && navigate('/aircraft/status');
            }
            
        }
    }, [location, navigate, hasCompletedWalkthrough]);
    useEffect(() => {
        const preventScroll = (e) => e.preventDefault();

        if (!hasCompletedWalkthrough) {
            window.addEventListener("scroll", preventScroll, { passive: false });
            window.addEventListener("wheel", preventScroll, { passive: false });
            window.addEventListener("touchmove", preventScroll, { passive: false });
        } else {
            window.removeEventListener("scroll", preventScroll);
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("touchmove", preventScroll);
        }
        return () => {
            window.removeEventListener("scroll", preventScroll);
            window.removeEventListener("wheel", preventScroll);
            window.removeEventListener("touchmove", preventScroll);
        };
    }, [hasCompletedWalkthrough]);

    const contextValue = {
        formStep,
        changeStep,
        prevStep,
        resetState,
        markAsComplete,
    };
    return(
        <WalkthroughContext.Provider value={contextValue}>
            { !hasCompletedWalkthrough && <>
                {getStep(formStep)}
            </> }
            {children}
        </WalkthroughContext.Provider>
    )
    
}