import { useMutation, useQuery } from "@apollo/client";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import { FullFormikCheckBox, FullFormikInput } from "components/Form/StandardForm";
import Modal from "components/Modal/Modal";
import { Form, Formik } from "formik";
import { GetIntervalDocument, RemoveIntervalDocument, UpdateIntervalDocument } from "graphql/generated";
import { useState } from "react";

export default function Edit({ closeFlyout, id }: { closeFlyout: () => void; id: string }) {
    const { data: { interval } = {} } = useQuery(GetIntervalDocument, { variables: { id } });
    const [ deleteInterval ] = useMutation( RemoveIntervalDocument, { onCompleted: closeFlyout } );
    const [ updateInterval ] = useMutation( UpdateIntervalDocument, { onCompleted: closeFlyout } );
    const [ showModal, setShowModal ] = useState(false);
    return (
      <>
        { showModal && <Modal message={"Deleting an interval may effect linked inspections, previous flights, and logbooks"} cancel={setShowModal} accept={() => deleteInterval({ variables: { id } })} />}
        <Formik enableReinitialize initialValues={interval} onSubmit={(values) => {
            updateInterval({ variables: { input: { id, name: values.name, optional: values.optional } } });
        } }>
            <Form className="p-4">
                <Card title="">
                    <div className="flex flex-col space-y-4">
                        <FullFormikInput name="name" label="Name" />
                    </div>
                    <div className="flex flex-col space-y-4">
                        <FullFormikCheckBox name="optional" isVertical label="Is Optional" />
                    </div>
                </Card>
                <div className="flex pt-4 gap-2 justify-end">
                    <Button text="Delete" color="red" size="xs" onClick={() => setShowModal(true)} />
                    <Button text="Save" color="navy" size="xs" type="submit" />
                </div>
            </Form>
        </Formik>
      </>
    );
}